import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from "react-i18next";
import { useLanguage } from '../hook/useLocalStorage';
const LanguageSwitcher = ({ languagee }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();
  const tex = useLanguage()
  const { language, setLanguage } = useLanguage();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("lann", language);
    setLanguage(language)
    setAnchorEl(null)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (lang) => {
    handleClose();
  };

  return (
    <Box
      sx={{
      
     
      }}
      className="z-20"
    >
      <IconButton onClick={handleClick}>
        <LanguageIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
        <MenuItem onClick={() => changeLanguage('ar')}>العربية</MenuItem>
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;
