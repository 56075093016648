import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import LinkIcon from "@mui/icons-material/Link";
import { MdOutlinePermMedia } from "react-icons/md";
import { CiLogout } from "react-icons/ci";

export const links = [
  { name: "display", icon: <ArtTrackIcon /> },
  {
    name: "personal information",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 4.5C23 6.43 21.43 8 19.5 8C17.57 8 16 6.43 16 4.5C16 2.57 17.57 1 19.5 1C21.43 1 23 2.57 23 4.5ZM19.5 10C18.0421 9.99736 16.6447 9.41705 15.6138 8.38617C14.583 7.35529 14.0026 5.95788 14 4.5C14 4 14.08 3.5 14.21 3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C20.11 21 21 20.11 21 19V9.79C20.5 9.92 20 10 19.5 10Z"
          fill="#e5cd79"
        />
      </svg>
    ),
  },
  { name: "links", icon: <LinkIcon /> },
  {
    name: "Media",
    icon: (
      <MdOutlinePermMedia
        style={{
          fontWeight: "bold",
          fontSize: "1.3rem",
          margin: "0.3rem 0",
          marginRight: "0.3rem",
        }}
      />
    ),
  },

  {
    name: "Logout",
    icon: (
      <CiLogout
        color="#FF0000"
        style={{
          fontWeight: "bold",
          fontSize: "1.3rem",
          margin: "0.3rem 0",
          marginRight: "0.3rem",
        }}
      />
    ),
  },
];
