import React, { useRef, useState, useEffect } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCoverImage, setProfileImage } from "../Redux/Slices/FormSlice";
import { FaRegTrashAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import CropperDemoForProfile from "./CropperDemoForProfile";
import { Add } from "@mui/icons-material";
import { Cropper } from "react-cropper";

const UploadPhoto = ({ type }) => {
  const dispatch = useDispatch();
  const cropperRef = useRef();
  const [coverPath, setCoverPath] = useState();

  const { profileImage, coverImage } = useSelector((state) => state.form);
  const theme = useTheme();
  const { t } = useTranslation();
  const imageRef = useRef();  
  const [tempImagePath, setTempImagePath] = useState(null);
  const [openCropper, setOpenCropper] = useState(false);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageURL = URL.createObjectURL(e.target.files[0]);
      setTempImagePath(imageURL);
  
      // Update the correct path state based on the type
      if (type === "cover") {
        setCoverPath(imageURL);  // Ensure the cover path is set for cropping
      }
  
      setOpenCropper(true);
    }
    e.target.value = null; // Reset the value of the input to ensure it can trigger onChange again with the same file
  };
  
  const getCoverCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
        const file = new File([blob], "cropped.jpg", { type: "image/jpeg" });
        getCroppedFile(file);
      }, 'image/jpeg');
    }
  };
  const getCroppedFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result;
      if (type === "profile") {
        dispatch(setProfileImage({ image: base64data }));
      } else {
        dispatch(setCoverImage({ coverImage: base64data }));
      }
      setOpenCropper(false);
      setTempImagePath(null);
    };
  };

  const handleCancel = () => {
    setOpenCropper(false);
  };
  const isEmpty = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  
  return (
    <Box py={"1rem"}>
      <Stack alignItems={"center"} direction={"row"} gap={"1rem"}>
        <Box
          sx={{
            backgroundColor: "#F8F9FA",
            backgroundImage: `url(${
              type === "profile" ? profileImage : coverImage
            })`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            borderRadius: "10px",
            width: "20.2em",
            height: "10.2em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            border: "2px dashed #ccc",
            position: "relative",
          }}
          onClick={() => {
            imageRef.current.click();
          }}
        >
        {(!profileImage || isEmpty(profileImage)) && type === "profile" || 
        (!coverImage || isEmpty(coverImage)) && type === "cover" ? (
            <>
              <Typography
                variant="body2"
                sx={{
                  color: "#666",
                  textAlign: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {type === "profile" ? t("Upload_Profile_Photo") : t("UploadـCoverـPhoto")}
              </Typography>
              <Add
                sx={{
                  color: "#666",
                  position: "absolute",
                  top: "70%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </>
          ) : (
            <FaRegTrashAlt
              style={{
                color: "red",
                fontSize: "1.1rem",
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (type === "profile") {
                  dispatch(setProfileImage({ image: null }));
                } else {
                  dispatch(setCoverImage({ coverImage: null }));
                }
              }}
            />
          )}
        </Box>
      </Stack>
      <input
        onChange={handleImageChange}
        type="file"
        accept="image/*"
        ref={imageRef}
        hidden
      />
      {openCropper && type === "profile" && (
        <CropperDemoForProfile
          src={tempImagePath}
          getCroppedFile={getCroppedFile}
          onCancel={handleCancel}
        />
      )}
{openCropper && type === "cover" && (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "rgba(0, 0, 0, 0.73)",
      zIndex: 10000,
      padding: { xs: "2rem", md: "4rem" },
    }}
  >
    <Cropper
      ref={cropperRef}
      src={coverPath}
      style={{
        maxHeight: "40vh",
        width: "100%",
        maxWidth: "300px"
      }}
      aspectRatio={16 / 9} // Standard aspect ratio for cover images
      viewMode={1}
      background={false}
      responsive={true}
      autoCropArea={1}
      guides={true}
      cropBoxResizable={true}
      dragMode="move"
    />
  
    <Stack direction="row" spacing={2} mt={2}>
      <Button
        sx={{ width: "fit-content" }}
        variant="contained"
        color="success"
        onClick={getCoverCropData}
      >
        {t("crop")}
      </Button>
      <Button
        sx={{ width: "fit-content" }}
        variant="contained"
        color="error"
        onClick={handleCancel}
      >
      {t("cancel")}
      </Button>
    </Stack>
  </Box>
)}

      
    </Box>
  );
};

export default UploadPhoto;
