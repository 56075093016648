import React ,{useState}from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from "@mui/material";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsFiletypePdf } from "react-icons/bs";
import tinycolor from 'tinycolor2';

const Temp18 = () => {
  const form = useSelector(state => state.form);
  const mainBackgroundColor = form.colors.mainBackground;
  const buttonsBackground = form.colors.buttonsBackground;
  const textColor = '#ffffff';
  const textButtonColor = '#ffffff';
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");
  React.useEffect(() => {
    const buttonsBackground = form?.colors?.buttonsBackground;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form?.colors?.buttonsBackground]);

  return (
    <div className=" w-[280px] h-[500px]" style={{
      position: 'relative',
     overflowX:'hidden',
     
      height: '500px', // Full viewport height
     
    }}>
    <div className='absolute w-[280px] h-[500px] rounded-3xl overflow-auto' style={{
      backgroundColor: '#619A98',
      opacity: "0.5",
      zIndex: "100",
     top:'0',
     left :"0",
     bottom:0
    
    }}></div>

    <div 
    className='absolute w-[280px] h-[500px] rounded-3xl ' style={{
    
     
      position: 'absolute',
    
      width: '300px', // Width of a typical smartphone in portrait mode
      height: '500px', // Height of a typical smartphone in portrait mode
      backgroundColor: '#619A98', // Fallback color
      backgroundImage: `url(${form?.profileImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      overflowY: 'hidden', // Enables vertical scrolling within this div
      bottom:0,
     top:'0',
     left :"0"
    }}
     />

      {/* Scrollable Content Container */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'auto', // Enables vertical scrolling within this div
        zIndex: 200
      }}>

        <Box sx={{ p: 5 }} style={{ maxWidth: '100vw',zIndex:"300" }}>
       <div className='absolute  top-1/3 left-0 right-0   flex flex-col items-center justify-center p-5 pb-[200px]  ' style={{ zIndex:"400",overflow: "auto"}}>
    {/* User Info and Social Media Links */}
    <div className='flex flex-col items-center gap-4 mb-5'>
      <p className='text-3xl font-bold' style={{ fontFamily: "Acumin-BdItPro", color: textColor }}>
        {form?.basic?.firstName || ""} {form?.basic?.lastName || ""}
      </p>
      <div>
        {form?.basic?.jobTitle && <h2 className="font-semibold" style={{ color: textColor }}>{form?.basic?.jobTitle}</h2>}
        {form?.basic?.businessName && <h2 className="text-sm" style={{ color: textColor }}>{form?.basic?.businessName}</h2>}
        {form?.basic?.location && <h2 className="text-sm" style={{ color: textColor }}>{form?.basic?.location}</h2>}
        {form?.basic?.bio && <h2 className="text-xs font-semibold" style={{ color: textColor }}>{form?.basic?.bio}</h2>}
      </div>
      <div className='flex gap-3'>
        <div 
         className='py-3 px-3 rounded-full bg-opacity-50' style={{ backgroundColor: buttonsBackground }}>
          <FaPhone color={textbottoncolor} size={22} />
        </div>
        <div className='py-3 px-3 rounded-3xl bg-opacity-50' style={{ backgroundColor: buttonsBackground }}>
          <AiOutlineMail color={textbottoncolor} size={22} />
        </div>
        <div   className='py-3 px-3 rounded-3xl bg-opacity-50' style={{ backgroundColor: buttonsBackground }}>
          <FaWhatsapp color={textbottoncolor} size={22} />
        </div>
      </div>
    </div>
    {/* Additional Sections and Links */}
    <div className='flex flex-col items-center w-full max-w-screen-lg mt-10'>
      <div className='grid md:grid-cols-3 grid-cols-4 gap-3 mt-3'>
        {form?.contacts.map((item, index) => (
          <div key={index} className='w-14 flex justify-center items-center'>
            <img
              style={{
                maxWidth: "100%",
                borderRadius: "10px",
              }}
              src={`${item?.logo}`}
              alt="social logo"
            />
          </div>
        ))}
        {form?.contactsCustom.map((item, index) => (
          <div key={index} className='w-14 flex justify-center items-center'>
            <img
              style={{
                maxWidth: "100%",
                borderRadius: "10px",
              }}
              src={`${item?.logo}`}
              alt="custom logo"
            />
          </div>
        ))}
      </div>
      
      

      



      <div className=" flex flex-col gap-3 mt-12 ">
        {form?.sections.map((item, index) => {
          const url = item.media;

          const isPDF = url?.endsWith(".pdf");

          return (
            <div className="">
              
              <div
                key={index}
                className="flex flex-col gap-2 justify-center items-center "
              > 
                { ( isPDF || (item && item.content && item.content.length > 0 && (item.content[0]?.type === 'pdf'))) ? (
                  <div className="flex flex-col items-center justify-center mb-14">
                    <h2
                      className="text-xl md:text-xl  p-4"
                      style={{ color: textColor }}
                    >
                      {item.sectionName}
                    </h2>
                    <div className='w-[250px]  rounded-2xl border-4  ' style={{backgroundColor:form?.colors.buttonsBackground,borderColor:textColor,borderRadius: "20px"}}>
                      <a href={url} target='_blank' style={{ borderRadius: "40px"}} >
                          <div className='flex justify-center  w-full   rounded-2xl shadow-lg  relative'  >
                          <div className='flex flex-row gap-5'>
                        <BsFiletypePdf
                                        size={90}
                                        className="w-full mt-5 mb-5 "
                                        color={textButtonColor}
                                      />
                    
                          </div>
                          <div className='absolute top-3 right-3'>
                          <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                          <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                          <path fill={textButtonColor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                          </svg>
                          </div>
                          </div>
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="  ">
                    <div className="flex justify-center my-3">
                      <h2
                        className="text-xl  "
                        style={{ color: textColor }}
                      >
                        {item.sectionName}
                      </h2>
                    </div>
                    <div className=" flex justify-center ">
                      
                      <video
                        className={`rounded-3xl border-4 min-w-[250px] h-[150px]  aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                        style={{
                          borderColor: `${textColor}`,
                          background: `${form?.colors.buttonsBackground}`,                              }}
                        controls

                              
                                
                              muted  // This will mute the video, enabling it to   in more browsers
                              preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                             
                      >
                        <source src={url} type="video/mp4" className="" />
                      </video>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>


    </div>
  </div>

        </Box>
      </div>
    </div>
  );
};

export default Temp18;
