import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCustomChangeContacts,
  handleCustomImageChangeContacts,
  setContacts,
  setCustomContacts,
  setSecondaryActions,
  removeCustomContacts,
  removeContacts,
  setContactsOrder,
} from "../Redux/Slices/FormSlice";
import { Box, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useLanguage } from "../hook/useLocalStorage";
import { FaTrash } from 'react-icons/fa';

const DraggableList = ({ buttonNames, type }) => {
  const form = useSelector((state) => state.form);
  const { t } = useTranslation();
  const [inputFields, setInputFields] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [customizableInputs, setCustomizableInputs] = useState([]);
  const dispatch = useDispatch();
  const { tex } = useLanguage();
  const [isExpanded, setIsExpanded] = useState(false); // State variable for expansion

  useEffect(() => {
    return () => {
      // Cleanup function
      setInputFields([]); // Reset inputFields to an empty array
      setInputValues({}); // Reset inputValues to an empty object
      setCustomizableInputs([]); // Reset customizableInputs to an empty array
    };
  }, []);

  useEffect(() => {
    setInputFields(
      (form.contacts ?? []).map((contact) => {
        const matchedButton = buttonNames.find(
          (button) => button.name === contact.type
        );

        return {
          idInthback: contact.idintheback,
          id: contact.type,
          name: contact.type,
          logo: contact?.logo || "",
          placeholder: matchedButton?.placeholder || "",
          value: contact.value,
        };
      })
    );
  }, [form.contacts, buttonNames]);

  useEffect(() => {
    setInputValues(
      form.contacts.reduce((values, contact) => {
        values[contact.type] = contact.value;
        return values;
      }, {})
    );
  }, [form.contacts]);

  useEffect(() => {
    const arrayOfCustomInputs = (form.contactsCustom ?? []).map(
      (customInput) => ({
        id: customInput.id,
        name: customInput.link ? customInput.link : customInput.name,
        logo:
          customInput.logo !== null && customInput.logo !== ""
            ? customInput.logo
            : customInput.image,
      })
    );

    setCustomizableInputs(arrayOfCustomInputs);
  }, [form.contactsCustom]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = (buttonName) => {
    const newId = buttonName.name;
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);

    const newInputField = {
      id: newId,
      name: buttonName.name,
      logo: buttonName.logo,
      placeholder: buttonName.placeholder,
      value: "", // Initialize value as an empty string or set it to a default value
    };

    setInputFields((prevInputFields) => {
      const updatedFields = [newInputField, ...prevInputFields];
      dispatch(setContactsOrder({ newOrder: updatedFields.map((input) => input.id) }));
      return updatedFields;
    });

    setInputValues((prevInputValues) => ({
      [newId]: "", // Initialize value as an empty string or set it to a default value
      ...prevInputValues,
    }));

    if (type === "primary") {
      dispatch(
        setContacts({
          type: newId,
          idInthback: buttonName.id,
          value: "", // Initialize value as an empty string or set it to a default value
          logo: buttonName.logo, // Include the logo when adding a new contact
        })
      );
    }

    if (type === "secondary") {
      dispatch(
        setSecondaryActions({
          type: newId,
          value: "", // Initialize value as an empty string or set it to a default value
          logo: buttonName.logo, // Include the logo when adding a new contact
        })
      );
    }
  };



  const handleInputChange = (id, value) => {
    setInputValues((prevInputValues) => ({
      [id]: value,
      ...prevInputValues,
    }));

    if (type === "primary")
      dispatch(
        setContacts({
          type: id,
          value,
        })
      );

    if (type === "secondary")
      dispatch(
        setSecondaryActions({
          type: id,
          value,
        })
      );
  };

  const handleInputClose = async (id, idInthback) => {
    setInputFields((prevInputFields) =>
      prevInputFields.filter((field) => field.id !== id)
    );
    setInputValues((prevInputValues) => {
      const { [id]: _, ...rest } = prevInputValues;
      return rest;
    });
    dispatch(removeContacts({ type: id }));
  };

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
  };

  const handleDrop = (e, targetId) => {
    e.preventDefault();

    const draggedId = e.dataTransfer.getData("text/plain");
    const draggedField = inputFields.find((field) => field.id === draggedId);

    if (draggedField) {
      const newInputFields = inputFields.filter(
        (field) => field.id !== draggedId
      );
      const targetIndex = inputFields.findIndex(
        (field) => field.id === targetId
      );

      newInputFields.splice(targetIndex, 0, draggedField);
      setInputFields(newInputFields);

      // Update positions in the state
      const updatedPositions = {};
      newInputFields.forEach((field, index) => {
        updatedPositions[field.id] = index;
      });

      dispatch(
        setContactsOrder({ newOrder: newInputFields.map((input) => input.id) })
      );
    }
  };

  const handleAddCustomizableInput = () => {
    const newInputName = ""; // Define a default name for the new input

    if (isDuplicateCustomInput(newInputName, -1)) {
      return;
    }
    dispatch(
      setCustomContacts({
        id: `${customizableInputs.length + 1}`,
        name: "",
        logo: "",
        value: "",
      })
    );

    setCustomizableInputs((prevInputs) => [
      {
        id: `${prevInputs.length + 1}`,
        name: "",
        logo: "",
        value: "",
      },
      ...prevInputs, // Prepend new custom input
    ]);
  };

  const handleCustomInputChange = (index, field, value) => {
    if (isDuplicateCustomInput(value, index)) {
      alert("This input already exists. Please enter a unique value.");
      return;
    }
    setCustomizableInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[index][field] = value;

      dispatch(handleCustomChangeContacts({ index, field, value }));

      return updatedInputs;
    });
  };

  const handleRemoveCustomInput = (index) => {
    setCustomizableInputs((prevInputs) =>
      prevInputs.filter((_, i) => i !== index)
    );
    dispatch(removeCustomContacts(index));
  };

  const filteredButtonNames = buttonNames.filter((buttonName) =>
    buttonName?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const groupedButtons = [];
  for (let i = 0; i < filteredButtonNames.length; i++) {
    const buttonPair = filteredButtonNames.slice(i, i + 1);
    groupedButtons.push(buttonPair);
  }

  const handleFileInputChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCustomizableInputs((prevInputs) => {
          const updatedInputs = [...prevInputs];
          updatedInputs[index].logo = reader.result;
          dispatch(handleCustomImageChangeContacts({ index, reader }));
          return updatedInputs;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const filteredOptions = groupedButtons.filter(
    (buttonPair) =>
      !inputFields.some((field) =>
        buttonPair.some((buttonName) => field.name === buttonName.name)
      )
  );

  const optionCount = filteredOptions.length;

  const isDuplicateCustomInput = (value, currentIndex) => {
    return customizableInputs.some(
      (input, index) => index !== currentIndex && input.name === value
    );
  };

  const scrollAmount = 100; // Adjust as needed

  const scrollLeft = () => {
    const container = document.getElementById("your-scrollable-div-id");
    container.scrollLeft -= scrollAmount;
  };

  const scrollRight = () => {
    const container = document.getElementById("your-scrollable-div-id");
    container.scrollLeft += scrollAmount;
  };
  return (
    <div
    
   className="max-w-[95vw]"
    >
      <div>
        {optionCount !== -1 ? (
          <div
            className="hide-scrollbar bg-[#F7F7F7]"
            
            style={{
              display: "flex",
              justifyContent: tex?.language === "ar" ? "end" : "start",
              width: "full",
              // Take full width of the grid column
              backdropFilter: "blur(8px)",
              border: "1px solid rgba(229, 205, 121, 0.5)",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              overflowX: "auto",
              whiteSpace: "nowrap",

            }}
          >
            <div className="relative w-[98%] h-24">
              <div
                className="absolute h-[82%] p-1 flex justify-center items-center bg-[#F7F7F7]"
                style={{
                  left: 17,
                  borderRadius: "10px",
                  top: "42%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  // Style your arrow button
                }}
              >
                <MdOutlineKeyboardArrowLeft
                  color="#000"
                  size={30}
                  onClick={() => scrollLeft()}
                />
              </div>
              <div className="flex">
                <div
                  id="your-scrollable-div-id"
                  className="hide-scrollbar ml-4 px-[200px]"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: "10px",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    background: "#F7F7F7",
                    padding: "1rem",
                   
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {groupedButtons
                    .filter(
                      (buttonPair) =>
                        !inputFields.some((field) =>
                          buttonPair.some(
                            (buttonName) => field.name === buttonName.name
                          )
                        )
                    )
                    .map((buttonPair, index) => (
                      <div
                        key={index}
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          marginRight: "0rem",
                          marginLeft: "0rem",
                          cursor: "pointer",
                        }}
                        onClick={handleClose}
                      >
                        {buttonPair.map((buttonName, buttonIndex) => (
                          <React.Fragment key={buttonName.name}>
                            <img
                              className="rounded-md"
                              src={buttonName.logo}
                              alt={buttonName.name}
                              style={{
                                width: "3.6rem",
                                height: "3.6rem",
                                cursor: "pointer",
                                marginLeft: buttonIndex === 0 ? "40px" : "0", // Apply 10px margin to the first logo
                              }}
                              onClick={() => handleButtonClick(buttonName)}
                            />
                            <h2 className="opacity-0">asdsad</h2>
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "2.5rem",
                    }}
                  >
                    <button
                      onClick={handleAddCustomizableInput}
                      className="mr-10"
                      style={{
                        background: "#262626",
                        backdropFilter: "blur(8px)",
                        border: "1px solid rgba(229, 205, 121, 0.5)",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                        padding: "0.4rem 0.6rem",
                        borderRadius: "4px",
                        color: "#fff",
                        cursor: "pointer",
                        width: "160px",
                        fontSize: "1rem",
                        letterSpacing: "0.5px",
                        transition: "transform 0.3s ease",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.transform = "scale(1.05)")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.transform = "scale(1)")
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "0.3rem",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <AddIcon
                          style={{
                            color: "#fff",
                            fontSize: "2rem",
                          }}
                        />
                        <div className="text-sm whitespace-nowrap text-white">
                          {t("Add_Extra_Link")}
                        </div>
                      </div>
                    </button>
                  </Box>
                </div>
              </div>
              <div
                className="absolute h-[82%] flex justify-center p-2 items-center bg-[#F7F7F7]"
                style={{
                  right: 1,
                  borderRadius: "10px",
                  top: "42%",
                  transform: "translateY(-50%)",
                  zIndex: 2,
                  // Style your arrow button
                }}
              >
                <MdOutlineKeyboardArrowRight
                  color="#000"
                  size={30}
                  onClick={() => scrollRight()}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          id="your-scrollable-div-id"
          style={{
            maxHeight: "300px", // Maximum height for the input container
            overflowY: "auto", // Enable vertical scrolling on overflow
            background: "#f7f7f7", // Light gray background
            borderRadius: "10px", // Rounded corners
            padding: "1rem", // Padding around the contents
          }}
        >
          {inputFields.map((inputField) => (
            <div
              key={inputField.id}
              style={{
                display: "flex",
                justifyContent: tex === "ar" ? "end" : "start",
                alignItems: "center",
                marginBottom: "0.5rem",
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                padding: "0.5rem",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <img
                  src={inputField.logo}
                  alt="User"
                  style={{
                    width: "3.6rem",
                    height: "3.6rem",
                    zIndex: "10",
                    marginTop: "0.5rem",
                    borderRadius: "25%",
                    marginRight: "0.3rem",
                  }}
                />
                <TextField
                  size="small"
                  label={inputField.name}
                  id="outlined-basic"
                  type="text"
                  value={inputValues[inputField.id] || ""}
                  placeholder={inputField.placeholder}
                  onChange={(e) =>
                    handleInputChange(inputField.id, e.target.value)
                  }
                  className="bg-text custom-gray-border"
                  style={{
                    width: "100%",
                    marginTop: "0.53rem",
                  }}
                  InputLabelProps={{
                    style: { color: "black" }, // Label color
                  }}
                  InputProps={{
                    style: {
                      color: "gray", // Input text color
                    },
                  }}
                  variant="outlined"
                />
              </div>
              <button
                onClick={() =>
                  handleInputClose(inputField.id, inputField.idInthback)
                }
                style={{
                  background: "transparent",
                  border: "none",
                  color: "#949494",
                  cursor: "pointer",
                  fontSize: "1.4rem",
                  marginLeft: "0.5rem", // Added margin for spacing
                }}
              >
              <FaTrash size={20} color='red' style={{ marginRight: "0.4rem" }} />

              </button>
            </div>
          ))}
          {inputFields.length !== 0 ? (
            <div className="flex justify-center">
              <div
                style={{
                  borderBottom: "1px solid gray",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  width: "90%",
                }}
              ></div>
            </div>
          ) : (
            ""
          )}
          {customizableInputs.map((customInput, index) => (
            <div
              key={`container_${index}`} // Added key to the outermost div
              style={{
                display: "flex",
                justifyContent: tex === "ar" ? "end" : "start",
                alignItems: "center",
                marginBottom: "0.5rem",
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                padding: "0.5rem",
                width: "100%", // Ensures full width like inputFields
              }}
            >
              <div
                key={`custom_${index}`}
                className="input-field"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <label
                  htmlFor={`custom_input_${index}`}
                  style={{
                    zIndex: "10",
                    borderRadius: "25%",
                    cursor: "pointer",
                  }}
                >
                  {customInput.logo ? (
                    <img
                      src={customInput.logo}
                      alt="Custom"
                      style={{
                        width: "2.7rem",
                        height: "2.7rem",
                        zIndex: "10",
                        marginTop: "0.5rem",
                        borderRadius: "25%",
                        marginRight: "0.3rem",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <>
                      <div
                        style={{
                          backgroundColor: "gray",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "2.7rem",
                          height: "2.7rem",
                          marginTop: "0.5rem",
                          borderRadius: "25%",
                          marginRight: "0.3rem",
                        }}
                      >
                        <span
                          style={{
                            color: "white",
                            fontSize: "1.5rem",
                            textAlign: "center",
                          }}
                        >
                          +
                        </span>
                      </div>
                      <input
                        id={`custom_input_${index}`}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileInputChange(index, e)}
                        style={{ display: "none" }}
                      />
                    </>
                  )}
                </label>
                <TextField
                  size="small"
                  id={`custom_input_${index}`}
                  label={t("custom_input")}
                  variant="outlined"
                  type="text"
                  value={customInput.name}
                  onChange={(e) =>
                    handleCustomInputChange(index, "name", e.target.value)
                  }
                  className="bg-text custom-gray-border"
                  style={{
                    width: "100%",
                    marginTop: "0.53rem",
                  }}
                  InputLabelProps={{
                    style: { color: "black" }, // Label color
                  }}
                  InputProps={{
                    style: {
                      color: "gray", // Input text color
                    },
                  }}
                />
              </div>
              <button
                onClick={() => handleRemoveCustomInput(index)}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "#949494",
                  cursor: "pointer",
                  fontSize: "1.4rem",
                  marginLeft: "0.5rem", // Added margin for spacing
                }}
              >
              <FaTrash size={20} color='red' style={{ marginRight: "0.4rem" }} />

              </button>
            </div>
          ))}
          {customizableInputs.length !== 0 ? (
            <div className="flex justify-center">
              <div
                style={{
                  borderBottom: "1px solid gray",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  width: "90%",
                }}
              ></div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default DraggableList;
