import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setColors } from "../Redux/Slices/FormSlice";
import { useTranslation } from "react-i18next";
import { SketchPicker } from 'react-color';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const ColorPicker = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useSelector((state) => state.form);
  const [openModal, setOpenModal] = useState(false);
  const [customBgColor, setCustomBgColor] = useState(colors.mainBackground);
  const [customBtnColor, setCustomBtnColor] = useState(colors.buttonsBackground);
  const [selectedPaletteIndex, setSelectedPaletteIndex] = useState(-1);
  const handlePaletteChange = (bgColor, btnColor, index) => {
    dispatch(setColors({ mainBackground: bgColor, buttonsBackground: btnColor }));
    setSelectedPaletteIndex(index);
  };

  useEffect(() => {
    const index = backgroundPalettes.findIndex((bgColor, idx) => bgColor === colors.mainBackground && buttonPalettes[idx] === colors.buttonsBackground);
    if (index !== -1) {
      setSelectedPaletteIndex(index);
    } else {
      setSelectedPaletteIndex(-1);
    }
  }, [colors]);

  const handleOpenModal = () => {
    setOpenModal(true);
    setCustomBgColor(colors.mainBackground);
    setCustomBtnColor(colors.buttonsBackground);
  };
  const handleCloseModal = () => setOpenModal(false);

  const handleBgColorChange = (color) => {
    setCustomBgColor(color.hex);
  };

  const handleBtnColorChange = (color) => {
    setCustomBtnColor(color.hex);
  };

  const applyCustomColors = () => {
    dispatch(setColors({ mainBackground: customBgColor, buttonsBackground: customBtnColor }));
    handleCloseModal();
  };

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const backgroundPalettes = [
    "#fff",
    "#f0f7ee",
    "#c5c5c5",
    "#3f3f37",
    "#393d3f",
    "#080708",
    "#f6d8af",
    "#b7ad99",
    "#c5afa4",
    "#002a32",
    "#ef798a",
    "#785964",
    "#2e3532",
    "#463f3a",
    "#595959",
    "#0d1321",
    "#cecccc",
    "#ff5e5b",
    "#db5461",
    "#0a2342",
    "#a49e8d",
    "#ada8b6",
    "#32292f",
    "#c1c1c1",
    "#70d6ff",
    "#eaebed",
    "#080705",
    "#ebbab9",
    "#05a8aa",
    "#8c271e",
  ];
  const buttonPalettes = [
    "#000",
    "#c4d7f2",
    "#4c5b61",
    "#d6d6b1",
    "#fdfdff",
    "#3772ff",
    "#2e4057",
    "#ff4365",
    "#cc7e85",
    "#c4a29e",
    "#f7a9a8",
    "#82a7a6",
    "#8b2635",
    "#8a817c",
    "#8a817c",
    "#ffeddf",
    "#9d6381",
    "#d8d8d8",
    "#ffd9ce",
    "#2ca58d",
    "#504136",
    "#ffeedb",
    "#575366",
    "#2c4251",
    "#ff70a6",
    "#006989",
    "#40434e",
    "#c9c5ba",
    "#b8d5b8",
    "#aba194",
  ];
  return (
    <>
    <Typography variant="h6" gutterBottom>
      Our suggestions:
    </Typography>
    <Grid container spacing={2} className="overflow-y-auto">
      {backgroundPalettes.map((bgColor, index) => (
        <Grid item xs={4} sm={4} md={4} key={index}>
          <Box
            sx={{
              width: isSmallScreen ? "30px" : "70px",
              height: isSmallScreen ? "30px" : "70px",
              cursor: "pointer",
              background: `linear-gradient(to right, ${bgColor} 50%, ${buttonPalettes[index]} 50%)`,
              borderRadius: "50%",
              boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2)",
              border: selectedPaletteIndex === index ? "2px solid gold" : "none",
              "&:hover": {
                borderColor: "gold",
              }
            }}
            onClick={() => handlePaletteChange(bgColor, buttonPalettes[index], index)}
          />
        </Grid>
      ))}
    </Grid>
    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      You can now make your own color combinations from millions of colors
    </Typography>
    <Button
      onClick={handleOpenModal}
      sx={{
        borderRadius: "50%",
        width: isSmallScreen ? "30px" : "70px",
        height: isSmallScreen ? "30px" : "70px",
        color: "#fff",
        background: 'linear-gradient(135deg, #6B73FF, #000DFF 50%, #007BFF)',
        border: selectedPaletteIndex === -1 ? "2px solid gold" : "none",
        minWidth: 0,
        padding: 0,
        "&:hover": {
          background: 'linear-gradient(135deg, #6B73FF, #000DFF 50%, #007BFF)',
          borderColor: "gold",
        }
      }}
    >
      <HelpOutlineIcon />
    </Button>
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description" 
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "& .MuiPaper-root": {
          width: "60%",
          maxWidth: "none"
        }
      }}
    >
      <Box sx={{
        bgcolor: '#fff', p: 4, borderRadius: 2, boxShadow: 24, width: '80%'
      }}>
        <Typography id="modal-title" variant="h6">
          {t("Select Custom Colors")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>{t("Background Color")}</Typography>
            <SketchPicker color={customBgColor} onChangeComplete={handleBgColorChange} disableAlpha presetColors={[]} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>{t("Button Color")}</Typography>
            <SketchPicker color={customBtnColor} onChangeComplete={handleBtnColorChange} disableAlpha presetColors={[]} />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button onClick={handleCloseModal}>{t("Cancel")}</Button>
          <Button variant="contained" onClick={applyCustomColors}>{t("Save")}</Button>
        </Box>
      </Box>
    </Modal>
  </>
  );
};

export default ColorPicker;
