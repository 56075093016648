import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "cropperjs/dist/cropper.css";
import { t } from "i18next";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CropperDemoForProfile({ src, getCroppedFile, onCancel }) {
  const cropperRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);

  const handleSave = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const file = new File([blob], "cropped.jpg", { type: "image/jpeg" });
      getCroppedFile(file);  // Now returning a File object instead of base64 string
    }, 'image/jpeg');
  };
  const handleCancel = () => {
    onCancel();  // Simply call onCancel, handling passed from parent component
  };

  const rotate = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.rotate(90);
  };

  const flip = (type) => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (type === "h") {
      const newScaleX = scaleX === 1 ? -1 : 1;
      cropper.scaleX(newScaleX);
      setScaleX(newScaleX);
    } else {
      const newScaleY = scaleY === 1 ? -1 : 1;
      cropper.scaleY(newScaleY);
      setScaleY(newScaleY);
    }
  };

  return (
    <Modal
      open={true}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {loading && (
          <Skeleton variant="rectangular" width="100%" height={400} />
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
          <ButtonGroup disableElevation variant="contained" size="small">
            <Button onClick={rotate}>{t("Rotate")}</Button>
            <Button onClick={() => flip("h")}> {t("FlipـH")}</Button>
            <Button onClick={() => flip("v")}>{t("FlipـV")}</Button>
          </ButtonGroup>
        </Box>

        <Cropper
          src={src}
          style={{ height: 400, width: "100%" }}
          aspectRatio={1}
          viewMode={1}
          guides={false}
          ready={() => setLoading(false)}
          ref={cropperRef}
          cropBoxResizable={false}
          dragMode="move"
          cropBoxMovable={false}
          background={false}
          autoCropArea={1}
          checkOrientation={false}
        />

        <style jsx>{`
          .cropper-view-box,
          .cropper-face {
            border-radius: 50%;
          }
          .submit-button {
            float: right;
            margin-top: 16px;
          }
          .cancel-button {
            float: left;
            margin-top: 16px;
          }
          @media (max-width: 600px) {
            .submit-button {
              position: fixed;
              bottom: 16px;
              right: 16px;
              margin-top: 0;
            }
            .cancel-button {
              position: fixed;
              bottom: 16px;
              left: 16px;
              margin-top: 0;
            }
          }
        `}</style>

        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
          <Button
            className="cancel-button"
            onClick={handleCancel}
            color="error"
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            className="submit-button"
            onClick={handleSave}
            autoFocus
            color="success"
            variant="contained"
          >
            {t("crop")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
