import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { IoMdContact } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { IoQrCodeOutline } from "react-icons/io5";
import { jsPDF } from "jspdf";
import { FaFileDownload } from "react-icons/fa";
import { useSelector } from "react-redux";
import qr from "./../../../assets/images/qrcode.png";
import copy from "./../../../assets/images/copy.png";
import tinycolor from "tinycolor2";
import { IoShareSocial } from "react-icons/io5";
import share from "./../../../assets/images/share.png";
import { FaPhoneAlt, FaPlus } from "react-icons/fa";
import { MdEmail, MdOutlineSaveAlt } from "react-icons/md";
import { BiGridAlt } from "react-icons/bi";
import { BsFiletypePdf } from "react-icons/bs";

import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";
import Modal from "@mui/material/Modal";

import QRCode from "qrcode.react";
import { PiUploadSimpleBold } from "react-icons/pi";
import ShareIcon from "@mui/icons-material/Share";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaPhoneVolume } from "react-icons/fa";

const ProgressiveImage = ({ lowResSrc, highResSrc }) => {
  const [src, setSrc] = useState(lowResSrc);

  useEffect(() => {
    const img = new Image();
    img.src = highResSrc;
    img.onload = () => {
      setSrc(highResSrc); // Set high-res image once it's loaded
    };
  }, [highResSrc]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${src})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        margin: "-50px auto 0",
        zIndex: 1000,
        transition: "0.5s filter linear",
        filter: `${src === lowResSrc ? "blur(20px)" : ""}`,
      }}
    />
  );
};

const Template2 = (profileImagee, profileImageLowRes) => {
  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");
  const [open, setOpen] = React.useState(false);
  const [oppen, setOppen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCopyLink = () => {
    // Replace 'your_link_here' with the actual link you want to copy
    const linkToCopy = "asd";

    // Create a temporary input element
    const tempInput = document.createElement("input");
    tempInput.value = linkToCopy;
    document.body.appendChild(tempInput);

    // Select and copy the content
    tempInput.select();
    document.execCommand("copy");

    // Remove the temporary input
    document.body.removeChild(tempInput);

    console.log("Link copied to clipboard:", linkToCopy);
    // You can add additional logic or feedback here if needed
  };

  const handleWhatsAppShare = () => {
    // Replace 'your-website-url' with the actual URL you want to share
    const shareUrl = "ads";

    // Construct the WhatsApp share link
    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(shareUrl)}`;

    // Open the link in a new tab/window
    window.open(whatsappLink, "_blank");
  };

  const handleSpeedDialClick = () => {
    // Code to send the SMS with the link goes here
    // For example, you can use the "sms:" protocol to open the default SMS app with a pre-filled message.
    const message = `Check out this link: asd`;
    window.open(`sms:?body=${encodeURIComponent(message)}`, "_blank");
  };

  const handleDownloadPdf = () => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Add content to the PDF (you can customize this part)
    pdf.text("Hello, this is your PDF!", 10, 10);

    // Save the PDF as a Blob
    const pdfBlob = pdf.output("blob");

    // Create a download link and trigger the download
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(pdfBlob);
    downloadLink.download = "myPDF.pdf";
    downloadLink.click();
  };

  const form = useSelector((state) => state.form);

  console.log(form);

  const [profileImage, setProfileImage] = useState("");
  const [coverImage, setCoverImage] = useState("");

  // //console.log();

  useEffect(() => {
    if (form?.profileImage) setProfileImage(form.profileImage);
  }, [form?.profileImage]);

  useEffect(() => {
    if (form?.coverImage) setCoverImage(form.coverImage);
  }, [form?.coverImage]);

  useEffect(() => {
    const mainBackgroundColor = form?.colors?.mainBackground;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();

      // Adjust text color based on brightness
      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form?.colors?.mainBackground]);

  useEffect(() => {
    const buttonsBackground = form?.colors?.buttonsBackground;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();

      // Adjust text color based on brightness
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form?.colors?.mainBackground]);
  const captureScreenshot = () => {
    const targetComponent = document.getElementById("mainComponent"); // Replace with your component's ID
    if (targetComponent) {
      html2canvas(targetComponent)
        .then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL();
          link.download = "screenshot.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error capturing screenshot:", error);
        });
    }
  };
  const saveAsPDF = async () => {
    const targetComponent = document.getElementById("mainComponent");
    if (targetComponent) {
      try {
        const canvas = await html2canvas(targetComponent);
        const imgData = canvas.toDataURL("image/png");

        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const { width, height } = page.getSize();
        const img = await pdfDoc.embedPng(imgData);
        const { width: imgWidth, height: imgHeight } = img.scale(1);

        page.drawImage(img, {
          x: 0,
          y: 0,
          width: imgWidth,
          height: imgHeight,
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: "application/pdf" });

        // Use FileSaver.js library to save the blob as a file
        const saveAs = require("file-saver");
        saveAs(blob, "screenshot.pdf");
      } catch (error) {
        console.error("Error saving as PDF:", error);
      }
    }
  };

  const saveToContacts = () => {
    const contact = {
      name: form.basic.firstName + " " + form.basic.lastName,
      mobile: form.basic.phoneNumber || "",
      email: form.basic.email || "",
      address: form.basic.location || "", // You can add the address from the form if available
      organization: form.basic.businessName || "",
      title: form.basic.jobTitle || "",
    };

    const vcard =
      `BEGIN:VCARD\nVERSION:3.0\n` +
      `N:${contact.name};;;\n` +
      `FN:${contact.name}\n` +
      `TEL;CELL:${contact.phone}\n` +
      `TEL;CELL:${contact.mobile}\n` +
      `EMAIL;HOME:${contact.email}\n` +
      `ADR;HOME:${contact.address}\n` +
      `ORG;WORK:${contact.organization}\n` +
      `TITLE:${contact.title}\n` +
      `NOTE:${contact.notes}\n` +
      `END:VCARD`;

    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);

    if (navigator.share) {
      navigator
        .share({
          title: "New Contacts",
          text: "Save contacts",
          files: [new File([blob], "newcontact.vcf", { type: "text/vcard" })],
        })
        .then(() => {});
    } else {
      const newLink = document.createElement("a");
      newLink.download = `${contact.name}.vcf`;
      newLink.textContent = contact.name;
      newLink.href = url;

      newLink.click();
    }
  };

  return (
    <div
    className="overflow-hidden w-[280px] "
      sx={{ position: "relative" ,background: form?.colors?.mainBackground,}}
       id="mainComponent" 
    >
      

    

    

      <Stack
        sx={{
          background: form?.colors?.mainBackground,
          paddingBottom: "40px",
          position: "relative",
          minHeight:'500px'
     
        }}
        gap={3}
      >
        <Box
          sx={{
            background: form?.colors?.mainBackground,
            borderRadius: "15px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${coverImage})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              height: "150px",
              zIndex: "100",
            }}
          ></Box>

          <div
            className={`w-[100%] h-10 flex justify-between flex-row-reverse  relative`}
          >
            <div className={`relative w-[50%] `}>
              <div
                class="line bent  z-10 "
                style={{
                  background: form?.colors.buttonsBackground,
                  borderColor: form?.colors.buttonsBackground,
                  transform: "rotate(-1.5deg)",
                }}
              ></div>

              <div
                className="absolute z-20 rounded-r-full top-3 left-[10px] w-[120%] h-[240%]"
                style={{
                  background: form?.colors?.mainBackground,
                  transform: "rotate(0deg)",
                }}
              ></div>
              <div
                class="line bent  z-20 absolute top-[8px] left-1.5"
                style={{
                  background: form?.colors?.mainBackground,
                  transform: "rotate(8deg)",
                }}
              ></div>
            </div>

            <div className={`relative w-[50%] `}>
              <div
                class="line bent2  z-10"
                style={{
                  background: form?.colors.buttonsBackground,
                  borderColor: form?.colors.buttonsBackground,
                  transform: "rotate(1.5deg)",
                }}
              ></div>
              <div
                className="absolute z-20 rounded-l-full top-3 right-[10px] w-[120%] h-[240%]"
                style={{
                  background: form?.colors?.mainBackground,
                  transform: "rotate(0deg)",
                }}
              ></div>
              <div
                class="line bent  z-20 absolute top-[7px] right-5"
                style={{
                  background: form?.colors?.mainBackground,
                  transform: "rotate(0.5deg)",
                }}
              ></div>
            </div>
          </div>
          <Stack
            maxWidth={"80%"}
            m={"0 auto"}
            gap={"0.1rem"}
            sx={{ textAlign: "center", position: "relative" }}
          >
            <Box
              sx={{
                backgroundImage: `url(${
                  typeof profileImage === "string" && profileImage !== ""
                    ? profileImage
                    : ""
                })`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeate",
                backgroundSize: "cover",
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                backgroundColor: form?.colors.buttonsBackground,
                margin: "-75px auto 0",
                border: "14px solid ",
                borderColor:
                  form?.colors.buttonsBackground !== undefined
                    ? form?.colors.buttonsBackground
                    : "black",
                zIndex: "1000",

                border: "4px solid black",
              }}
            ></Box>
            <Grid my={"3rem"} gap={"1rem"} justifyContent={"center"} container>
              <Grid
                item
                sx={{
                  width: "60px",
                  borderRadius: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.5rem",
                }}
              >
                <button className="text-[33px]">
                  <IoLogoWhatsapp color={textcolor} />
                </button>
              </Grid>

              <Grid
                item
                sx={{
                  width: "60px",
                  borderRadius: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.5rem",
                  cursor: "pointer", // Add this line to show it's clickable
                }}
              >
                <button className="text-[33px]">
                  <MdEmail color={textcolor} />
                </button>
              </Grid>

              <Grid
                item
                sx={{
                  width: "60px",
                  borderRadius: "14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "1.5rem",
                  cursor: "pointer", // Add this line to show it's clickable
                }}
              >
                <button className="text-[28px] z-10 ">
                  <FaPhoneVolume
                    color={textcolor}
                    className=" -rotate-[30deg]"
                  />
                </button>
              </Grid>
            </Grid>
            <Typography
              color={textcolor}
              style={{
                fontSize: "1.5rem",
                width: "full",
                fontFamily: "Robote_bold",
                zIndex: 20,
              }}
            >
            {form?.basic?.firstName !== null &&
              form?.basic?.firstName !== "null"? form?.basic?.firstName :''}
              {"\n"}
              {form?.basic?.lastName !== null &&
                form?.basic?.lastName !== "null"? form?.basic?.lastName :''}
            </Typography>

            {form?.basic?.jobTitle !== null &&
              form?.basic?.jobTitle !== "null" && (
                <Typography
                  color={textcolor}
                  style={{
                    fontSize: "0.9rem",
                    fontFamily: "Robote",
                    fontWeight: "600",
                    width: "full",
                    zIndex: 20,
                  }}
                >
                  {" "}
                  {form?.basic?.jobTitle}
                </Typography>
              )}
            {form?.basic?.businessName !== null &&
              form?.basic?.businessName !== "null" && (
                <Typography
                  color={textcolor}
                  style={{
                    marginTop: "-3px",
                    fontSize: "0.8rem",
                    fontFamily: "Robote",
                    fontWeight: "500",
                    width: "full",
                    zIndex: 20,
                  }}
                >
                  {form?.basic?.businessName}
                </Typography>
              )}
            {form?.basic?.location !== null &&
              form?.basic?.location !== "null" && (
                <Typography
                  color={textcolor}
                  style={{
                    fontSize: "0.6rem",
                    fontFamily: "Robote",
                    fontWeight: "400",
                    width: "full",
                    zIndex: 20,
                  }}
                >
                  {" "}
                  {form?.basic?.location}
                </Typography>
              )}
            {form?.basic?.bio !== null && form?.basic?.bio !== "null" && (
              <Typography
                mb={"1rem"}
                color={textcolor}
                style={{
                  fontSize: "0.8rem",
                  fontFamily: "Robote",
                  fontWeight: "500",
                  width: "full",
                  zIndex: 20,
                }}
              >
                {form?.basic?.bio}
              </Typography>
            )}
            

            <div className="grid grid-cols-3 justify-center items-center gap-y-7 mt-8 place-items-center">
              {form?.contacts.map((item) => {
                return (
                  <Grid
                    item
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "14px",
                      // background: form.colors.buttonsBackground,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.5rem",
                    }}
                  >
                    {item.logo ? (
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            borderRadius: "10px",
                          }}
                          src={`${item?.logo}`}
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Grid>
                );
              })}

              {form?.contactsCustom.map((item) => {
                //console.log(item);
                return (
                  <Grid
                    item
                    sx={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "14px",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.5rem",
                    }}
                  >
                    {item?.logo && (
                      <Box
                        sx={{
                          backgroundImage: `url(${item?.logo})`,
                          backgroundRepeate: "no-repeate",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                        }}
                      ></Box>
                    )}

                    {item?.image && (
                      <Box
                        sx={{
                          backgroundImage: `url(${item?.image})`,
                          backgroundRepeate: "no-repeate",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          width: "100%",
                          height: "100%",
                          borderRadius: "8px",
                        }}
                      ></Box>
                    )}
                  </Grid>
                );
              })}
            </div>
 





            <div className=" flex flex-col gap-3 mt-12">
              {form?.sections.map((item, index) => {
                const url = item.media;

                const isPDF = url?.endsWith(".pdf");
  
                return (
                  <div className="">
                    
                    <div
                      key={index}
                      className="flex flex-col gap-2 justify-center items-center "
                    > 
                      { ( isPDF || (item && item.content && item.content.length > 0 && (item.content[0]?.type === 'pdf'))) ? (
                        <div className="flex flex-col items-center justify-center mb-14">
                          <h2
                            className="text-2xl md:text-3xl Robote_bold p-4"
                            style={{ color: textcolor }}
                          >
                            {item.sectionName}
                          </h2>
                          <div className='w-[250px]  rounded-2xl border-4  ' style={{backgroundColor:form?.colors.buttonsBackground,borderColor:textcolor,borderRadius: "20px"}}>
                            <a href={url} target='_blank' style={{ borderRadius: "40px"}} >
                                <div className='flex justify-center  w-full   rounded-2xl shadow-lg  relative'  >
                                <div className='flex flex-row gap-5'>
                              <BsFiletypePdf
                                              size={90}
                                              className="w-full mt-5 mb-5 "
                                              color={textbottoncolor}
                                            />
                          
                                </div>
                                <div className='absolute top-3 right-3'>
                                <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                                <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                                <path fill={textbottoncolor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                                </svg>
                                </div>
                                </div>
                            </a>
                          </div>
        
        
                        </div>
                      ) : (
                        <div className="  ">
                          <div className="flex justify-center my-3">
                            <h2
                              className="text-2xl md:text-3xl Robote_bold"
                              style={{ color: textcolor }}
                            >
                              {item.sectionName}
                            </h2>
                          </div>
                          <div className=" flex justify-center ">
                            
                            <video
                              className={`rounded-3xl border-4 min-w-[250px] h-[150px]  aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                              style={{
                                borderColor: `${textcolor}`,
                                background: `${form?.colors.buttonsBackground}`,
                                                               }}
                              controls

                              
                                
                              muted  // This will mute the video, enabling it to   in more browsers
                              preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                             

                              
                                
                              muted  // This will mute the video, enabling it to   in more browsers
                              preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                             
                            >
                              <source src={url} type="video/mp4" className="" />
                            </video>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>











          </Stack>
        </Box>
        <Stack
          gap={"1rem"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        ></Stack>
      </Stack>
    </div>
  );
};

export default Template2;
