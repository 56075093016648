import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState,useRef} from "react";
import ColorPicker from "../../Components/ColorPicker";
import { MotionBox } from "./Components/MotionComponent";

import TemplatePreview from "../../Components/TemplatePreview";
import SwiperComponent from "../../Components/Swiper";
import { useTranslation } from "react-i18next";
import { LanguageProvider, useLanguage } from "../../hook/useLocalStorage";
import { useMediaQuery } from "@mui/material"; // Import useMediaQuery for responsive design
import { useScroll, useTransform } from "framer-motion";

const Step1 = () => {
  const [showPreview, setShowPreview] = useState(false);
  const { t } = useTranslation();
  
  const tex = useLanguage();
  useEffect(()=>{
console.log(tex?.language,'textextextex')
  },[tex])
  const isArabic = tex?.language === "ar";
  const profile = localStorage.getItem("profile");

  const isLargeScreen = useMediaQuery('(min-width:500px)');

  // Determine if the profile is null and the screen is large
  const adjustLayout = isLargeScreen && profile === "null";

  return (
    <>

      
      <Grid spacing={2} container justifyContent="space-between" fontFamily={"Acumin-BdItPro"} >
        <Grid item md={6} xs={12}>
       
<div className= {`flex  w-screen ${tex?.language === "ar" ? "justify-end" : "justify-start"}`}  >
          <Box sx={{ padding: "1rem 0.5rem" }} className=" w-screen md:w-2/3">
            <Box sx={{ padding: "1rem 0.5rem", marginBottom: "1rem" }} className={` ${tex?.language=='ar' ? "mr-20" : "mr-0"}`}>
            <Typography
            variant="h1"
            sx={{
              color: "#353D4B",
              
              textAlign: tex?.language === "ar" ? "end" : "start",
              fontSize: {
                xs: "1.2rem", // Adjust font size for smaller screens
                md: "1.5rem", // Default font size for medium and larger screens
              },
              marginLeft: {
                xs: tex?.language === "en" ? "3px" : "0rem",
                md: tex === "en" ? "-5px" : "0rem",
              },
              marginRight: {
                xs: tex?.language === "ar" ? "12px" : "0rem",
                md: tex?.language === "ar" ? "-5px" : "0rem",
              },
            }}
          >
            {t("Choose_Your_Template_Colors")}
          </Typography>
            </Box>
            
            <ColorPicker />
          </Box>
          </div>
          <Box
          sx={{
            position: { xs: "relative", md: "fixed" },
            top: { xs: "unset", md: "10%" },
            left: isArabic ? "0%" : "unset",
            right: !isArabic ? "0%" : "unset",
            transform: { xs: "none", md: "translateY(-10%)" },
         
            width: { xs: "100%", md: "36vw", lg: "33vw" },
            height: { xs: "auto", md: "100vh" },
            overflowY: { xs: "visible", md: "auto" },
            transition: "0.3s ease-in-out",
            opacity: { xs: "1", md: "1" },
            marginTop: { xs: "1rem", md: "0" },
            marginLeft: { xs: "0", md: "0" },
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            padding: { xs: "1rem", md: "2rem" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
        <Box
        sx={{
          display: 'block', // Ensure it's displayed on all screen sizes
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        <TemplatePreview />
   
      </Box>
        </Box>
        </Grid>

      </Grid>
    </>
  );
};

export default Step1;
