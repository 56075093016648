import ResetEmail from "../Components/ResetEmail";
import Reset from "../Components/ResetPassword";
import SendVeruficationToEmail from "../Components/sendVerificationCodeToEmail";
import ResendOTP from "../Components/resendOTP";
import Form from "../Forms/Form";
import Step1 from "../Forms/Steps/StepThree";
import Step2 from "../Forms/Steps/StepOne";
import Step3 from "../Forms/Steps/StepTwo";
import Dashboard from "../Pages/Dashboard";
import Settings from "../Pages/Settings";
import Steper from "../Pages/Steper";
import Template2 from "../Pages/Templates/Template2/Template2";
import PrivateRoutes from "./PrivateRoutes";
import Forgetpassword from "../Components/Forgetpassword";
import SendOtpForForgetPasword from "../Components/sendVerificationCodeToEmail";

const AuthenticationRoutes = {
  element: <PrivateRoutes />,

  children: [
    {
      element: <Steper />,
      path: "Steper",
    },
    {
      element: <Step1 />,
      path: "Step1",
    },
    {
      element: <Step2 />,
      path: "Step2",
    },
    {
      element: <Step3 />,
      path: "Step3",
    },
   
    {
      element: <Template2 />,
      path: "/templateTwo",
    },
    { element: <Settings />, path: "/settings" },
    {
      element: <ResendOTP />,
      path: "/resendOTP",
    },
    {
      element: <Reset />,
      path: "/Reset",
    },
    {
      element: <ResetEmail />,
      path: "/ResetEmail",
    },
    {
      element: <Dashboard />,
      path: "/Dashboard",
    },
    {
      element: <SendVeruficationToEmail />,
      path: "/resetpassword",
    },
    {
      element: <Forgetpassword />,
      path: "/forgetpassword",
    },
    {
      element: <SendOtpForForgetPasword />,
      path: "/sendotpforforgetpasword",
    },
    {
      element: <Reset />,
      path: "/updateforgetpasssword",
    },

  
  ],
};
export default AuthenticationRoutes;
