import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputAdornment, useMediaQuery } from "@mui/material"; // Import useMediaQuery for responsive design

import {
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Popper,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button
} from "@mui/material";
import { debounce } from "lodash";
import { MuiTelInput } from "mui-tel-input";
import { useTranslation } from "react-i18next";
import {
  setBasicInfo,
  setFirstName,
  setIsPersonal,
  setLastName,
  setLocation,
} from "../../Redux/Slices/FormSlice";
import UploadPhoto from "../../Components/UploadPhoto";
import TemplatePreview from "../../Components/TemplatePreview";
import countries from "./countries.json"; // Adjust the path as necessary
import { useLanguage } from "../../hook/useLocalStorage";
 
const Step2 = ({ formikRef }) => {
  const { t } = useTranslation();
  const tex = useLanguage();
  const form = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const [showPreview, setShowPreview] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const { language } = useLanguage();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]); // assuming you have a countries list
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [citySearchQuery, setCitySearchQuery] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const handleCitySearchChange = (event) => {
    setCitySearchQuery(event.target.value);
  };
  
  const handleCountryChange = (country, event) => {
    event.stopPropagation(); // Prevent the event from propagating further
    console.log("country", country);
    setSelectedCountry(country);
    setSearchQuery(country)
    setSelectedCity("");
    setCities(countries[country] || []);
    updateLocation(country, "");
    setOpen(false);
  };
  useEffect(() => {
    if (selectedCountry) {
      const filtered = cities.filter(city =>
        city.toLowerCase().includes(citySearchQuery.toLowerCase())
      );
      setFilteredCities(filtered);
    }
  }, [citySearchQuery, cities]);
  const [cityAnchorEl, setCityAnchorEl] = useState(null);
  
  const handleCityClickAway = (event) => {
    if (!cityAnchorEl.contains(event.target)) {
      setCityAnchorEl(null);
    }
  };
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cityAnchorEl && !cityAnchorEl.contains(event.target) && 
          !document.getElementById('city-popper').contains(event.target)) {
        setCityAnchorEl(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [cityAnchorEl]);
  
  // Assuming you have an array 'countries' and a method to filter them based on searchQuery
  useEffect(() => {
    const filtered = Object.keys(countries).filter((country) =>
      country.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCountries(filtered);
  }, [searchQuery]);

  const isArabic = language === "ar";

  const handleClickOutside = (event) => {
    // Check if the click is inside the anchorEl or popper content
    if (anchorEl && !anchorEl.contains(event.target) && 
        !(document.getElementById('popperId')?.contains(event.target))) {
      setOpen(false);
    }
  };
  
 
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl]);

  const updateLocation = (country, city) => {
    console.log(`${country}, ${city}`);
    dispatch(setLocation({ location: `${country}, ${city}` }));
  };
  const handleCityChange = (city) => {
    setSelectedCity(city);
    setCitySearchQuery(city);
    setCityAnchorEl(null);
    updateLocation(selectedCountry, city);
  };
  

  const replaceNullWithEmptyString = (values) => {
    if (!values || typeof values !== "object") {
      return {};
    }
    return Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value ?? ""])
    );
  };

  const debouncedDispatch = debounce((values) => {
    dispatch(setBasicInfo({ basic: values }));
  }, 500);

  const handleChange = (values) => {
    debouncedDispatch(values);
  };

  useEffect(() => {
    if (form?.basic?.location) {
      const [country, city] = form.basic.location.split(", ");
      setSearchQuery(country || "");
      setCitySearchQuery(city || "");
      setCities(countries[country] || []);
    }
  }, [form?.basic?.location]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  useEffect(() => {
    if (
      !formikRef.current.values.phoneNumber ||
      formikRef.current.values.phoneNumber === "0000000"
    ) {
      formikRef.current.setFieldValue("phoneNumber", "+966");
    }
  }, [formikRef]);

  const [activeOption, setActiveOption] = useState(1);

  useEffect(() => {
    if (form?.isPersonal === "true") {
      setActiveOption(1);
    }
     else if (form?.isPersonal === "false") {
      setActiveOption(2);
    }
  }, [form?.isPersonal]);

  const handleClick = (option) => {
    setActiveOption(option);
  };

  const getValidationSchema = () => {
    let schemaFields = {
      businessName: Yup.string().max(50, "Must be under 50 characters"),
      jobTitle: Yup.string().max(50, "Must be under 50 characters"),
      phoneNumber: Yup.string().required("Please complete the data"),
      email: Yup.string()
        .email("Invalid email")
        .required("Please complete the data")
        .max(50, "Must be under 50 characters"),
    };

    if (form?.isPersonal === "true" || form?.isPersonal === "") {
      schemaFields = {
        ...schemaFields,
        firstName: Yup.string()
          .required("Please complete the data")
          .max(50, "Must be under 50 characters"),
        lastName: Yup.string()
          .required("Please complete the data")
          .max(50, "Must be under 50 characters"),
      };
    } else if (form?.isPersonal === "false") {
      schemaFields.businessName = schemaFields.businessName.required(
        "Please complete the data"
      );
    }

    return Yup.object(schemaFields);
  };

  const handleSubmit = (values) => {};
  const profile = localStorage.getItem("profile");

  const isLargeScreen = useMediaQuery('(min-width:300px)');

  // Determine if the profile is null and the screen is large
  const adjustLayout = isLargeScreen && profile === "null";

  return (
    <Box className={`flex ${adjustLayout === true && isArabic === true ? 'justify-center ' : adjustLayout === true && isArabic === false ? 'justify-center' : ''} overflow-x-hidden w-screen ${isArabic ? "justify-end " : "justify-start"}`}>
    <div className={`${adjustLayout ? "w-full" : "md:w-[60%]"} max-w-[95vw]`}>
   {/**    <div className="w-screen flex flex-col items-center md:w-full my-10">
        <Typography
          className="mb-8"
          variant="h4"
          sx={{
            color: "#353D4B",
            padding: '1rem',
            textAlign: tex?.language === "ar" ? "end" : "start",
            fontSize: {
              xs: "1.2rem",
              md: "1.5rem",
            }
          }}
        >
          {t("Are_you_using_Xtag_as_an_individual_or_a_company")}
        </Typography>
        <div className="flex justify-between rounded-md overflow-hidden my-2 w-48">
          <Button
            variant={activeOption === 1 ? "contained" : "outlined"}
            color="primary"
            className="w-1/2"
            onClick={() => {
              handleClick(1);
              dispatch(setIsPersonal({ isPersonal: "true" }));
            }}
          >
            {t("individual")}
          </Button>
          <Button
            variant={activeOption === 2 ? "contained" : "outlined"}
            color="primary"
            className="w-1/2"
            onClick={() => {
              handleClick(2);
              dispatch(setIsPersonal({ isPersonal: "false" }));
         
              dispatch(setFirstName({ firstName: null }));
              dispatch(setLastName({ lastName: null }));
            }}
          >
            {t("Company")}
          </Button>
        </div>
      </div>
*/}

<Box sx={{ padding: "1rem 0.5rem", marginBottom: "1rem" }} className={` ${tex?.language=='ar' ? "mr-20" : "mr-0"}`}>
<Typography
variant="h1"
sx={{
  color: "#353D4B",
  
  textAlign: tex?.language === "ar" ? "end" : "start",
  fontSize: {
    xs: "1.2rem", // Adjust font size for smaller screens
    md: "1.5rem", // Default font size for medium and larger screens
  },
  marginLeft: {
    xs: tex?.language === "en" ? "3px" : "0rem",
    md: tex === "en" ? "-5px" : "0rem",
  },
  marginRight: {
    xs: tex?.language === "ar" ? "12px" : "0rem",
    md: tex?.language === "ar" ? "-5px" : "0rem",
  },
}}
>
{t("PersonalـData")}
</Typography>
</Box>
<div className="flex flex-col lg:flex-row justify-center mx-4 my-10">
       
        <Grid item md={6} xs={12} style={{
          marginRight: isLargeScreen && isArabic ? '20px' : '0',
        }}
      >
        <Formik
          innerRef={formikRef}
          initialValues={replaceNullWithEmptyString(form?.basic)}
          enableReinitialize={true}
          validationSchema={getValidationSchema()}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
             
               
             
          
              {activeOption === 1 && (
                <>
                  <Grid item xs={12} md={6} mb={3}>
                    <Box position="relative" width="100%">
                      <Typography
                        variant="body1"
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                          position: 'absolute',
                          top: '-26px',
                          right: isArabic ? 0 : 'unset',
                          left: isArabic ? 'unset' : 0,
                          textAlign: isArabic ? 'right' : 'left',
                        }}
                      >
                        {t("FirstـName")}
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="firstName"
                        type="text"
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                            ? <span style={{ textAlign: isArabic ? 'right' : 'left', display: 'block' }}>{formik.errors.firstName}</span>
                            : ''
                        }
                        {...formik.getFieldProps("firstName")}
                        onChange={(e) => {
                          e.persist();
                          formik.handleChange(e);
                          handleChange({
                            ...formik.values,
                            firstName: e.target.value,
                          });
                        }}
                        placeholder={t("FirstـName")}
                        inputProps={{
                          style: {
                            color: 'black',
                            textAlign: isArabic ? 'right' : 'left',
                            borderColor: 'black',
                          },
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'black',
                            },
                            '&:hover fieldset': {
                              borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'black',
                            },
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} mb={3}>
                    <Box position="relative" width="100%" >
                      <Typography
                        variant="body1"
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                          position: 'absolute',
                          top: '-26px',
                          right: isArabic ? 0 : 'unset',
                          left: isArabic ? 'unset' : 0,
                          textAlign: isArabic ? 'right' : 'left',
                        }}
                      >
                        {t("LastـName")}
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="lastName"
                        type="text"
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                            ? <span style={{ textAlign: isArabic ? 'right' : 'left', display: 'block' }}>{formik.errors.lastName}</span>
                            : null
                        }
                        {...formik.getFieldProps("lastName")}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleChange({
                            ...formik.values,
                            lastName: e.target.value,
                          });
                        }}
                        placeholder={t("LastـName")}
                        inputProps={{
                          style: {
                            color: 'black',
                            textAlign: isArabic ? 'right' : 'left',
                            borderColor: 'black',
                          },
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'black',
                            },
                            '&:hover fieldset': {
                              borderColor: 'black',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: 'black',
                            },
                          },
                        }}
                      />
                    </Box>
                  </Grid>
                </>
              )}
              <>
              <Grid item xs={12} md={6} mb={3}>
                <Box position="relative" width="100%">
                  <Typography
                    variant="body1"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: '-26px', // Adjust this value to fine-tune the label position
                      right: isArabic ? 0 : 'unset',
                      left: isArabic ? 'unset' : 0,
                      textAlign: isArabic ? 'right' : 'left',
                    }}
                  >
                    {t("Business Name")}
                  </Typography>
                  <TextField
  fullWidth
  variant="outlined"
  id="businessName"
  type="text"
  error={formik.touched.businessName && Boolean(formik.errors.businessName)}
  helperText={
    formik.touched.businessName && formik.errors.businessName
      ? <span style={{ textAlign: isArabic ? 'right' : 'left', display: 'block' }}>{formik.errors.businessName}</span>
      : ''
  }
  {...formik.getFieldProps("businessName")}
  value={formik.values.businessName === "null" ? "" : formik.values.businessName} // Check the initial value
  onChange={(e) => {
    let value = e.target.value;
    if (value === "null") {
      value = ""; // Replace the string "null" with an empty string
    }
    formik.setFieldValue("businessName", value);
    handleChange({
      ...formik.values,
      businessName: value,
    });
  }}
  placeholder={t("Enter Business Name")}
  inputProps={{
    style: {
      color: 'black',
      textAlign: isArabic ? 'right' : 'left',
      borderColor: 'black',
    },
  }}
  sx={{
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  }}
/>

                
                </Box>
              </Grid>
              
              <Grid item xs={12} md={6} mb={3}>
                <Box position="relative" width="100%">
                  <Typography
                    variant="body1"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: '-26px',
                      right: isArabic ? 0 : 'unset',
                      left: isArabic ? 'unset' : 0,
                      textAlign: isArabic ? 'right' : 'left',
                    }}
                  >
                    {t("Position")}
                  </Typography>
                  <TextField
                  fullWidth
                  variant="outlined"
                  id="jobTitle"
                  type="text"
                  error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                  helperText={
                    formik.touched.jobTitle && formik.errors.jobTitle
                      ? <span style={{ textAlign: isArabic ? 'right' : 'left', display: 'block' }}>{formik.errors.jobTitle}</span>
                      : null
                  }
                  {...formik.getFieldProps("jobTitle")}
                  value={formik.values.jobTitle === "null" ? "" : formik.values.jobTitle} // Handle initial value
                  onChange={(e) => {
                    let value = e.target.value; 
                    if (value === "null") {
                      value = ""; // Replace the string "null" with an empty string
                    }
                    formik.setFieldValue("jobTitle", value);
                    console.log(form?.basic);
                    handleChange({
                      ...formik.values,
                      jobTitle: value,
                    });
                  }}
                  placeholder={t("Enter Position")}
                  inputProps={{
                    style: {
                      color: 'black',
                      textAlign: isArabic ? 'right' : 'left',
                      borderColor: 'black',
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}
                />
                
                </Box>
              </Grid>
            </>
              <Grid item xs={12} md={6} mb={3}>
                <Box position="relative" width="100%">
                  <Typography
                    variant="body1"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: '-26px',
                      right: isArabic ? 0 : 'unset',
                      left: isArabic ? 'unset' : 0,
                      textAlign: isArabic ? 'right' : 'left',
                    }}
                  >
                    {t("PhoneـNumber")}
                  </Typography>
                  <MuiTelInput
                    fullWidth
                    value={formik.values.phoneNumber}
                    onChange={(value) => {
                      formik.setFieldValue("phoneNumber", value);
                      handleChange({
                        ...formik.values,
                        phoneNumber: value,
                      });
                    }}
                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                        ? <span style={{ textAlign: isArabic ? 'right' : 'left', display: 'block' }}>{formik.errors.phoneNumber}</span>
                        : null
                    }
                    placeholder={t("PhoneـNumber")}
                    inputProps={{
                      style: {
                        color: 'black',
                        textAlign: isArabic ? 'right' : 'left',
                        borderColor: 'black',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>
          
              <Grid item xs={12} md={6} mb={3}>
                <Box position="relative" width="100%">
                  <Typography
                    variant="body1"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: '-26px',
                      right: isArabic ? 0 : 'unset',
                      left: isArabic ? 'unset' : 0,
                      textAlign: isArabic ? 'right' : 'left',
                    }}
                  >
                    {t("Email")}
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    type="email"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={
                      formik.touched.email && formik.errors.email
                        ? <span style={{ textAlign: isArabic ? 'right' : 'left', display: 'block' }}>{formik.errors.email}</span>
                        : null
                    }
                    {...formik.getFieldProps("email")}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChange({
                        ...formik.values,
                        email: e.target.value,
                      });
                    }}
                    placeholder={t("Email")}
                    inputProps={{
                      style: {
                        color: 'black',
                        textAlign: isArabic ? 'right' : 'left',
                        borderColor: 'black',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>
          
              <Grid item xs={12} md={6} mb={3}>
                <Box position="relative" width="100%">
                  <Typography
                    variant="body1"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: '-26px',
                      right: isArabic ? 0 : 'unset',
                      left: isArabic ? 'unset' : 0,
                      textAlign: isArabic ? 'right' : 'left',
                    }}
                  >
                    {t("Country")}
                  </Typography>
                  <TextField
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t("Country")}
                    inputProps={{
                      style: {
                        color: 'black',
                        textAlign: isArabic ? 'right' : 'left',
                        borderColor: 'black',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                  <Popper
                    id="popperId"
                    open={open}
                    anchorEl={anchorEl}
                    style={{
                      width: anchorEl?.clientWidth,
                      zIndex: 1000,
                    }}
                  >
                    <Paper
                      style={{
                        maxHeight: 200,
                        overflowY: "auto",
                        zIndex: 1000,
                      }}
                    >
                      <List dense>
                        {filteredCountries.map((country) => (
                          <ListItem
                            button
                            key={country}
                            onClick={(event) =>
                              handleCountryChange(country, event)
                            }
                          >
                            <ListItemText primary={country} />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Popper>
                </Box>
              </Grid>
          
              <Grid item xs={12} md={6} mb={3}>
                <Box position="relative" width="100%">
                  <Typography
                    variant="body1"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: '-26px',
                      right: isArabic ? 0 : 'unset',
                      left: isArabic ? 'unset' : 0,
                      textAlign: isArabic ? 'right' : 'left',
                    }}
                  >
                    {t("City")}
                  </Typography>
                  <TextField
                    fullWidth
                    value={citySearchQuery}
                    onChange={handleCitySearchChange}
                    onFocus={(event) => setCityAnchorEl(event.currentTarget)}
                    placeholder={t("City")}
                    inputProps={{
                      style: {
                        color: 'black',
                        textAlign: isArabic ? 'right' : 'left',
                        borderColor: 'black',
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'black',
                        },
                        '&:hover fieldset': {
                          borderColor: 'black',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'black',
                        },
                      },
                    }}
                  />
                  <Popper
                    id="city-popper"
                    open={Boolean(cityAnchorEl)}
                    anchorEl={cityAnchorEl}
                    style={{ width: cityAnchorEl?.clientWidth, zIndex: 1000 }}
                  >
                    <Paper style={{ maxHeight: 200, overflowY: 'auto' }}>
                      <List dense>
                        {filteredCities.map((city) => (
                          <ListItem button key={city} onClick={() => handleCityChange(city)}>
                            <ListItemText primary={city} />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Popper>
                </Box>
              </Grid>
          
              <Grid item xs={12}>
              <Box position="relative" width="100%">
                <Typography
                  variant="body1"
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '-26px',
                    right: isArabic ? 0 : 'unset',
                    left: isArabic ? 'unset' : 0,
                    textAlign: isArabic ? 'right' : 'left',
                  }}
                >
                  {t("Bio")}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="bio"
                  multiline
                  rows={4}
                  error={formik.touched.bio && Boolean(formik.errors.bio)}
                  helperText={
                    formik.touched.bio && formik.errors.bio
                      ? <span style={{ textAlign: isArabic ? 'right' : 'left', display: 'block' }}>{formik.errors.bio}</span>
                      : null
                  }
                  {...formik.getFieldProps("bio")}  // Directly using Formik's getFieldProps
                  placeholder={t("Bio")}
                  inputProps={{
                    style: {
                      color: 'black',
                      textAlign: isArabic ? 'right' : 'left',
                      borderColor: 'black',
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'black',
                      },
                      '&:hover fieldset': {
                        borderColor: 'black',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'black',
                      },
                    },
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange({
                      ...formik.values,
                      bio: e.target.value, 
                    });
                  }}
                />
              </Box>
            </Grid>
            
            </Grid>
          </form>
          
          )}
        </Formik>

        <Box
        sx={{
          padding: "1rem 0.5rem",
          textAlign: isArabic ? "end" : "start",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "#000",
            fontFamily: "Helvetica",
            textAlign: isArabic ? "end" : "start",
            fontSize: {
              xs: "1.2rem",
              md: "1.5rem",
            },
          }}
        >
          {t("ProfileـPhoto")}
        </Typography>
        <UploadPhoto type="profile" />
      </Box>

      <Box
        sx={{
          padding: "1rem 0.5rem",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "#000",
            fontFamily: "Helvetica",
            textAlign: isArabic ? "end" : "start",
            fontSize: {
              xs: "1.2rem",
              md: "1.5rem",
            },
          }}
        >
          {t("CoverـPhoto")}
        </Typography>
        <UploadPhoto type="cover" />
      </Box>
            </Grid>
      
      
          <Grid item md={6} xs={12}>
            {localStorage.getItem("profile") !== "null" && (
              <Box
                sx={{
                  position: { xs: "relative", md: "fixed" },
                  top: { xs: "unset", md: "10%" },
                  left: isArabic ? "0%" : "unset",
                  right: !isArabic ? "0%" : "unset",
                  transform: { xs: "none", md: "translateY(-10%)" },
                  width: { xs: "100%", md: "36vw", lg: "33vw" },
                  height: { xs: "auto", md: "100vh" },
                  overflowY: { xs: "visible", md: "auto" },
                  transition: "0.3s ease-in-out",
                  opacity: { xs: "1", md: "1" },
                  marginTop: { xs: "1rem", md: "0" },
                  marginLeft: { xs: "0", md: "0" },
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: { xs: "1rem", md: "2rem" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "block",
                    position: "relative",
                    overflowX: "hidden",
                  }}
                >
                  <TemplatePreview />
                </Box>
              </Box>
            )}
          </Grid>
      
      </div>
    </div>
  </Box>
  );
};

export default Step2;

 