import React from 'react';
import { useSelector } from 'react-redux';
import image from '../../../assets/images/person.jpg';
import Pdfi from '../../../assets/images/PDF-icon.png';
import { BsShareFill } from "react-icons/bs";
import { IoIosPhonePortrait } from "react-icons/io";
import { MdAlternateEmail } from "react-icons/md";
import { FaWhatsapp, FaFacebookF, FaTwitter } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";
import tinycolor from "tinycolor2";
import { BsFiletypePdf } from 'react-icons/bs';
const Template8 = () => {
    const form = useSelector(state => state.form);

    const socialMediaData = [
        { icon: FaFacebookF, color: '#1877F2', src: null },
        { icon: FaTwitter, color: '#1DA1F2', src: null }
    ];

    const pdfs = [
        { url: 'https://example.com/pdf1.pdf', name: 'PDF 1' },
        { url: 'https://example.com/pdf2.pdf', name: 'PDF 2' },
    ];

    const mainBackgroundColor = form.colors.mainBackground;
    const buttonsBackground = form.colors.buttonsBackground;
    const textColor = tinycolor(mainBackgroundColor).isDark() ? "#ffffff" : "#000000";
    const textButtonColor = tinycolor(buttonsBackground).isDark() ? "#ffffff" : "#000000";

    return (
        <div className='w-[280px] h-full' style={{ backgroundColor: mainBackgroundColor,fontFamily:"mynamar" }}>
         
            <div
                style={{
                    backgroundImage: `url(${form?.profileImage})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "45vh",
                    zIndex: 10,
                    transition: "0.5s filter linear",
                }}
            >
               
            </div>
            <div className='transform skew-y-12 w-full h -mt-14' style={{ backgroundColor: mainBackgroundColor }}>
                <div className="transform -skew-y-12">
                    <div className="flex flex-col justify-center items-center mt-4">
                      <h1 className="text-2xl text-left w-full pl-5" style={{ color: textColor }}>{form?.basic?.firstName}{" "}{form?.basic?.lastName}</h1>

{form?.basic?.jobTitle !==null && form?.basic?.jobTitle !=='null' &&
<h1
className="text-xl text-left w-full pl-5" 
  style=
 {{
  fontSize:"0.9rem", 
  
  fontWeight:"600",
  width:"full",
  zIndex:20,
  color: textColor
  }}
>
  {" "}
  {form?.basic?.jobTitle}
</h1>
}
                        <h1 className="text-xl text-left w-full pl-5" style={{ color: textColor }}>{form?.basic?.businessName}</h1>
                        <div className='w-full mt-3'> 
                            <div className='flex justify-start w-[100%] pl-5 gap-2'>
                                <p className='mt-1'><IoIosPhonePortrait color={textColor} /></p>
                                <p className='text-white' style={{ color: textColor }}>{form?.basic?.phoneNumber}</p>
                            </div>
                            <div className='flex justify-start w-[100%] pl-5 gap-2'>
                                <p className='mt-1'><MdAlternateEmail color={textColor} /></p>
                                <p className='text-white' style={{ color: textColor }}>{form?.basic?.email}</p>
                            </div>
                            <div className='flex justify-start w-[100%] pl-5 gap-2'>
                                <p className='mt-1 '><FaWhatsapp color={textColor} /></p>
                                <p className='text-white' style={{ color: textColor }}>{form?.basic?.phoneNumber}</p>
                            </div>
                            <div className='flex justify-start w-[100%] pl-5 gap-2'>
                                <p className='mt-1'><SlLocationPin color={textColor} /></p>
                                <p className='text-white' style={{ color: textColor }}>{form?.basic?.location}</p>
                            </div>
                        </div>
                        {form?.basic?.bio?
                          <div className="flex items-center justify-center mt-5 w-[90%]" >
                            <div className="w-[100%] rounded-2xl  p-4" style={{backgroundColor: buttonsBackground}}>
                              <p className="text-2xl bold " style={{color: textButtonColor}}>
                               About Us
                            </p>
                                <p className="text-sm " style={{color: textButtonColor}}>
                                    {form?.basic?.bio}
                                </p>
                            </div>
                        </div>
                        :
                        ''
                        }
                      
                        <div className="flex flex-col items-center justify-center my-5 w-full">
                       {/** <h1 className='text-2xl mb-2 w-full text-left pl-5 text-white'>Social Media</h1> */}    
                            {form?.contacts.map((item, index) => (
                                <div key={index} className="w-[90%] rounded-full  p-4 mt-2 h-[60px] flex justify-center items-center"style={{backgroundColor: buttonsBackground}}>
                                  
                                    <img
                          style={{
                          hieght:'50px', 
                          width: '50px', 
                            borderRadius: "8px",
                          }}
                          src={`${item?.logo}`}
                        />
                                </div>
                            ))}
                            {form?.contactsCustom.map((item, index) => (
                              <div key={index} className="w-[90%] rounded-full p-4 mt-2 h-[60px] flex justify-center items-center"style={{backgroundColor: buttonsBackground}}>
                                
                                  <img
                        style={{
                        hieght:'70px', 
                        width: '50px', 
                          borderRadius: "8px",
                        }}
                        src={`${item?.logo}`}
                      />
                              </div>
                          ))}
                        </div>
                      
                    </div>
                    
                </div>
            </div>
            <div className='h-[60px] -mb-[60px]   left-0 right-0 -mt-10 top-0 ' style={{ backgroundColor: mainBackgroundColor }}></div>
 
            <div className="flex flex-col gap-3 mt-12" style={{backgroundColor:mainBackgroundColor}}>
              {form?.sections.map((item, index) => {
                const url = item.media;
                const isPDF = url?.endsWith(".pdf");
            
                return (
                  <div key={index} className="flex flex-col gap-2 justify-between  w-full">
                    {(isPDF || (item && item.content && item.content.length > 0 && (item.content[0]?.type === 'pdf'))) ? (
                      <div className="flex flex-col items-center justify-center mb-14">
                        <h2
                          className="text-2xl md:text-3xl  p-4"
                          style={{ color: textColor }}
                        >
                          {item.sectionName}
                        </h2>
                       
                        <div className='w-[250px]  rounded-2xl border-4  ' style={{backgroundColor:form?.colors.buttonsBackground,borderColor:textColor,borderRadius: "20px"}}>
                          <a href={url} target='_blank' style={{ borderRadius: "40px"}} >
                              <div className='flex justify-center  w-full   rounded-2xl shadow-lg  relative'  >
                              <div className='flex flex-row gap-5'>
                            <BsFiletypePdf
                                            size={90}
                                            className="w-full mt-5 mb-5 "
                                            color={textButtonColor}
                                          />
                        
                              </div>
                              <div className='absolute top-3 right-3'>
                              <svg id="eye-Filled" xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                              <rect id="Path" width="26" height="24" fill="rgba(255,255,255,0)"/>
                              <path fill={textButtonColor} id="eye-Filled_1_" d="M369.234,2491.241c-1.375-2.14-4.444-5.74-9.612-5.74s-8.237,3.6-9.6,5.74a3.224,3.224,0,0,0,0,3.51c1.364,2.15,4.444,5.75,9.6,5.75s8.237-3.6,9.612-5.75a3.27,3.27,0,0,0,.522-1.75A3.33,3.33,0,0,0,369.234,2491.241Zm-9.612,6.26a4.509,4.509,0,1,1,4.8-4.5A4.662,4.662,0,0,1,359.622,2497.5Zm2.653-4.64a.619.619,0,0,1,.011.14,2.669,2.669,0,1,1-2.664-2.5,2.759,2.759,0,0,1,1.705.58,1.016,1.016,0,0,0-.639.92,1.037,1.037,0,0,0,1.066,1A1.014,1.014,0,0,0,362.276,2492.861Z" transform="translate(-346.836 -2481)"/>
                              </svg>
                              </div>
                              </div>
                          </a>
                        </div>

                  </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center">
                        <div className="flex justify-center my-3">
                          <h2 className="text-2xl md:text-3xl " style={{ color: textColor }}>
                            {item.sectionName}
                          </h2>
                        </div>
                        <div className="flex justify-center">
                          <video
                            className={`rounded-3xl border-4 min-w-[250px] h-[150px] aspect-video md:aspect-video mb-14 w-64 md:w-[43%]`}
                            style={{
                              borderColor: `${textColor}`,
                              background: `${form?.colors.buttonsBackground}`,
                            }}
                            controls

                              
                                
                              muted  // This will mute the video, enabling it to   in more browsers
                              preload="auto"  // This suggests to the browser that it should load the entire video when the page loads
                             
                          >
                            <source src={url} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            
            <div className='h-52  left-0 right-0 -mt-10 top-0 bottom-0 -z-10' style={{ backgroundColor: mainBackgroundColor }}></div>
        </div>
    );
};

export default Template8;
