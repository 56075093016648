import { Box, Typography, Stack, Tooltip, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ThemeColor } from "../../data/Theme";
import LinkIcon from "@mui/icons-material/Link";
import { useSelector } from "react-redux";
import DraggableList from "../../Components/DraggableList";
import TemplatePreview from "../../Components/TemplatePreview";
import DraggableFeatured from "../../Components/DraggableFeatured";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hook/useLocalStorage";
import { useMediaQuery } from "@mui/material"; // Import useMediaQuery for responsive design

const Step3 = () => {
  const [showMyLinksPopup, setShowMyLinksPopup] = useState(false); 
  const [primaryLinksFromApi, setPrimaryLinksFromApi] = useState([]);
  const { otherLinks } = useSelector((state) => state.form);
  const primaryLinksApi = "https://api.x-tag.tech/api/P_link";
  const { t } = useTranslation();
  const { language } = useLanguage();
  const isArabic = language === "ar";

  const fetchData = async () => {
    try {
      const response = await axios.get(primaryLinksApi, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          ContentType: "application/json",
        },
      });
      setPrimaryLinksFromApi(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => { 
    fetchData();
  }, []);
  const profile = localStorage.getItem("profile");

  const isLargeScreen = useMediaQuery('(min-width:500px)');

  // Determine if the profile is null and the screen is large
  const adjustLayout = isLargeScreen && profile === "null";

  return (
    <div className={`flex mt-10 ${isArabic==true?'justify-end':'justify-start'}   } overflow-x-hidden w-screen`}>
    <Box className={`w-[100%] md:w-[60%] ${isArabic ? "-mr-0 md:mr-10" : "ml-0"}`}>

  
        <Grid >
          <Grid item md={6} xs={12}>
            <Box>
              <Stack justifyContent={"space-between"} alignItems={"center"}>
                <Stack direction={"row"} gap={"1rem"}>
                  {otherLinks.length > 0 && (
                    <Tooltip title={"Show my Links"}>
                      <LinkIcon
                        sx={{
                          color: ThemeColor.title,
                          fontSize: "2.4rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowMyLinksPopup(!showMyLinksPopup);
                        }}
                      />
                    </Tooltip>
                  )}
                </Stack>
              </Stack>
              <Box>
                <div>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#000",
                      fontSize: "1rem", // Default font size for small screens
                      textAlign: isArabic ? "end" : "start",
                      marginRight: "1rem",
                      fontFamily: "Helvetica",
                      "@media (min-width: 600px)": {
                        fontSize: "1.3rem",
                      },
                    }}
                  >
                    {t("Links")}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#D9A31F",
                      fontSize: "1rem", // Default font size for small screens
                      textAlign: isArabic ? "end" : "start",
                      marginRight: "1rem",
                      marginTop: "10px",
                      fontFamily: "Helvetica",
                      "@media (min-width: 600px)": {
                        fontSize: "1.5rem",
                      },
                    }}
                  >
                  </Typography>
                  <Box
                    sx={{
                      margin: "0.8rem 0",
                      marginRight: "1rem",
                      marginLeft: "-0.4rem",
                    }}
                  >
                    <DraggableList
                      type="primary"
                      buttonNames={primaryLinksFromApi}
                    />
                  </Box>
                </div>
                <div className="mt-10">
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#000",
                      fontSize: "1rem", // Default font size for small screens
                      textAlign: isArabic ? "end" : "start",
                      marginRight: "1rem",
                      fontFamily: "Helvetica", // Center the text
                      "@media (min-width: 600px)": {
                        fontSize: "1.3rem", // Increase font size for larger screens
                      },
                      marginTop: "10px",
                    }}
                  >
                    {t("Add_your_Media")}
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#D9A31F",
                      fontSize: "1.3rem", // Default font size for small screens
                      textAlign: isArabic ? "end" : "start",
                      marginRight: "1rem",
                      fontFamily: "Helvetica", // Center the text
                      "@media (min-width: 600px)": {
                        fontSize: "1.5rem", // Increase font size for larger screens
                      },
                      marginTop: "10px",
                    }}
                  >
                  </Typography>
                  <Box sx={{ margin: "0.8rem 0" }}>
                    <DraggableFeatured />
                  </Box>
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            {localStorage.getItem('profile') !='null'&& (
              <Box
                sx={{
                  position: { xs: "relative", md: "fixed" },
                  top: { xs: "unset", md: "10%" },
                  left: isArabic ? "0%" : "unset",
                  right: !isArabic ? "0%" : "unset",
                  transform: { xs: "none", md: "translateY(-10%)" },
             
                  width: { xs: "100%", md: "36vw", lg: "33vw" },
                  height: { xs: "auto", md: "100vh" },
                  overflowY: { xs: "visible", md: "auto" },
                  transition: "0.3s ease-in-out",
                  opacity: { xs: "1", md: "1" },
                  marginTop: { xs: "1rem", md: "0" },
                  marginLeft: { xs: "0", md: "0" },
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  padding: { xs: "1rem", md: "2rem" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
              <Box
              sx={{
                display: 'block', // Ensure it's displayed on all screen sizes
                position: 'relative',
                overflowX: 'hidden',
              }}
            >
            
            
                <TemplatePreview />
              
            </Box>
              </Box>
            )}
            </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Step3;
