import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

export default function ResetEmail() {
  const [timerCount, setTimer] = useState(60);
  const [OTPinput, setOTPinput] = useState(["", "", "", "", ""]);
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const IsITpasswordchangeing = localStorage.getItem("passwordchange");
  function resendOTP() {
    if (disable) return;
    axios
      .post("http://localhost:5000/send_recovery_email", {
        // recipient_email: email,
      })
      .then(() => {
        setDisable(true);
        setTimer(60);
        alert("A new OTP has successfully been sent to your email.");
      })
      .catch((error) => {
        console.error("Error in sending OTP:", error);
        setError("Failed to resend OTP. Please try again later.");
      });
  }

  function verifyOTP() {
    setIsLoading(true);
    const otpCode = OTPinput.join("");
    const token = localStorage.getItem("token");
    // if (!token) {
    //   setError("Authentication token is missing.");
    //   return;
    // }

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    axios
      .post(
        "https://api.x-tag.tech/api/check_code",
        { code: otpCode },
        config
      )
      .then((response) => {
        console.log("OTP verification successful:", response.data);
        // If OTP verification is successful, proceed to change the email
        if (localStorage.getItem("thepassowrdforgoten") == "true") {
          navigate("/updateforgetpasssword");
        }

        // email: localStorage.getItem('email'),
        // password: newPassword,
        if (localStorage.getItem("passwordchange") == "true") {
          return axios.post(
            "https://api.x-tag.tech/api/change_password",
            {
              password: localStorage.getItem("newpassword"),
              email: localStorage.getItem("emailToChange"),
            },
            config
          );
        } else {
          return axios.put(
            "https://api.x-tag.tech/api/change_email",
            { email: localStorage.getItem("emailToChange") },
            config
          );
        }
      })
      .then((response) => {
        console.log("response:", response);
        // Handle email change success, e.g., navigate or show a message
        setIsLoading(false);
        navigate("/");
      })
      .catch((error) => {
        console.error("Error in process:", error);
        setIsLoading(false);
        setError("An error occurred. Please try again or re-send the link.");
      });
  }

  useEffect(() => {
    let interval = null;
    if (disable && timerCount > 0) {
      interval = setInterval(() => {
        setTimer((lastTimerCount) => lastTimerCount - 1);
      }, 1000);
    } else {
      setDisable(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [disable, timerCount]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        fontFamily: "Robote_bold",
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(142, 120, 92, 0.2)", // Semi-transparent background
          backdropFilter: "blur(10px)", // Blur effect for the glass look
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          border: "1px solid rgba(255, 255, 255, 0.2)", // Optional: add a light border for a more defined edge
          padding: "24px",

          width: {
            xs: "90%", // Smaller font size for extra-small screens
            sm: "80%", // Medium font size for small screens
            md: "70%", // Default font size for medium screens
            lg: "60%", // Larger font size for large screens
            xl: "50%", // Extra-large font size for extra-large screens
          },
          borderRadius: "16px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          color={"rgb(229, 205, 121)"}
          fontFamily="Robote_bold"
        >
          {t("Email_Verification")}
        </Typography>
        <Typography variant="body1" align="center" sx={{ color: "#fff" }}>
          {t("We_have_sent_a_code_to_your_email")}{" "}
          {localStorage.getItem("emailToChange")}
        </Typography>

        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column", gap: "24px", mt: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "300px",
              margin: "0 auto",
            }}
          >
            {OTPinput.map((_, index) => (
              <div className="mx-1">
                <TextField
                  key={index}
                  type="text"
                  variant="outlined"
                  inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                  onChange={(e) =>
                    setOTPinput([
                      ...OTPinput.slice(0, index),
                      e.target.value,
                      ...OTPinput.slice(index + 1),
                    ])
                  }
                />
              </div>
            ))}
          </Box>

          {error && <Typography color="error">{error}</Typography>}

          <Button
            variant="contained"
            onClick={verifyOTP}
            sx={{ bgcolor: "rgb(183, 148, 106)" }}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                style={{
                  color: "black",
                  position: "absolute", // To overlay the loader on the button
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px", // Half of the size to center it vertically
                  marginLeft: "-12px", // Half of the size to center it horizontally
                }}
              />
            ) : (
              <h4 style={{ color: "black", fontFamily: "Robote_bold" }}>
                {" "}
                {t("Verify_Account")}{" "}
              </h4>
            )}
          </Button>

          <Typography
            align="center"
            sx={{
              fontSize: "12px",
              color: "#737373",
              fontFamily: "Robote_bold",
            }}
          >
            <Button
              onClick={() => navigate(-1)}
              sx={{ marginTop: "1px", fontFamily: "Robote_bold" }}
            >
              {t("Back")}
            </Button>
            {/**   <Button disabled={disable} onClick={resendOTP} sx={{ color: disable ? "#fff" : "#fff", textDecoration: disable ? "none" : "underline" }}>
              {disable ? `Resend_OTP in ${timerCount}s` : 'Resend_OTP' }
            </Button>
            */}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
