import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Step1 from "../Forms/Steps/StepThree";
import Step2 from "../Forms/Steps/StepOne";
import Step3 from "../Forms/Steps/StepTwo";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Stepper, Step, StepLabel, Button, Modal, Typography, CircularProgress, useTheme, useMediaQuery } from "@mui/material";

import {
  setColors,
  setTheme,
  setProfileImage,
  setBasicInfo,
  setContacts,
  setCustomContacts,
  setCoverImage,
  setSections,
  setID,
  setIsPersonal,
} from "../Redux/Slices/FormSlice";
import { useNavigate } from "react-router-dom";
import { LanguageProvider, useLanguage } from "../hook/useLocalStorage";

const steps = ["Personal", "Media", "Colors"];

const StepperComponent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const formikRef = useRef();
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [showOverlay, setShowOverlay] = useState(true); // Initial state is true to show the overlay
  const [errorMessage, setErrorMessage] = useState("");
  const form = useSelector((state) => state.form);
  const { t } = useTranslation();
  const profile = localStorage.getItem("profile");
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tex = useLanguage();
  const scrollRef = useRef(null);  // Create a ref

  useEffect(() => {
    fetchAndDispatchProfileData();
  }, []);
 
  const handleErrorResponse = (error) => {
    let message = "An unexpected error occurred. Please try again.";
    if (!error.response) {
      message = "No response from the server. Please check your connection.";
    } 
     if (error ==422 && !error.response){
      message = "Invalid input. Please check your input and try again.";
    }
      
      else {
      switch (error?.response?.status) {
        case 500:
          message = "Server error. Please try again later.";
          break;
        case 420:
          message = "Rate limit exceeded. Please wait and try again later.";
          break;
        case 422:
          message = "Data validation error. Please check your inputs.";
          break;
        case 401:
          message = "Unauthorized access. Please log in again.";
          break;
        default:
          message = "An unexpected error occurred. Please try again.";
      }
    }
    setModalMessage(message);
    setModalOpen(true);
    setTimeout(() => {
        setModalOpen(false);
        handleModalCloseHessage()
    }, 3000);
    console.error("Error:", error);
  };
  const handleSuccessResponse = () => {
   
    showMessage(t("Data_saved"));
  };


  const handleModalCloseHessage = () => {
    setModalOpen(false);
  };

  const showMessage = (message, duration = 3000) => {
    setModalMessage(message);
    setModalOpen(true);
    setTimeout(() => {
      setModalOpen(false);
      handleModalCloseHessage()
    }, duration);
  };

  const theme = useTheme();
  const handleModalClose = () => {
    setShowModal(false);
    // navigate("/"); // Redirect to login page
    goToStep(0)
  };

  const fetchAndDispatchProfileData = async () => {
    localStorage.setItem("theme", -1);
    if (profile === null || profile === "null") {
      return;
    }

    try {
      if (profile !== null) {
        const response = await axios.get(
          `https://api.x-tag.tech/api/profile/${profile}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const formData = response?.data?.data;
        console.log("asdnaskjd,", formData);
        dispatch(
          setColors({
            buttonsBackground: formData?.buttonColor,
            mainBackground: formData?.bgColor,
          })
        );
        dispatch(setTheme({ theme: formData?.theme }));
        localStorage.setItem("theme", formData?.theme);

        dispatch(setID({ id: formData?.id }));
        dispatch(setIsPersonal({ isPersonal: formData?.isPersonal }));
        dispatch(setProfileImage({ image: formData?.photo }));
        dispatch(setCoverImage({ coverImage: formData?.cover }));
        dispatch(
          setBasicInfo({
            basic: {
              firstName:
                formData?.firstName !== "undefined" ? formData?.firstName : "",
              lastName:
                formData?.lastName !== "undefined" ? formData?.lastName : "",
              jobTitle: formData?.jobTitle,
              businessName: formData?.businessName,
              location: formData?.location,
              bio: formData?.bio !== "null" ? formData?.bio : "",
              phoneNumber: formData?.phoneNum,
              email: formData?.email,
              phoneNumSecondary: formData?.phoneNumSecondary,
            },
          })
        );

        formData?.primary_links?.forEach((contact) => {
          dispatch(
            setContacts({
              type: contact.name,
              value: contact.value,
              idInthback: contact.id,
              logo: contact.logo,
            })
          );
        });

        formData?.second_links?.forEach((customContact) => {
          dispatch(
            setCustomContacts({
              id: customContact.id,
              link: customContact.link,
              logo: customContact.logo,
              name_link: customContact.name_link,
            })
          );
        });

        const sectionsToAdd = formData?.section?.map((section) => ({
          id: section.id,
          sectionName: section.title,
          media: section.media,
          contentType: "video", // Assuming all sections are of type 'video'
        }));

        if (sectionsToAdd) {
          dispatch(setSections(sectionsToAdd));
        }
      }
    } catch (error) {
      handleErrorResponse(error);

      console.error("Error fetching profile data:", error);
    }
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  function base64ToFile(base64String, fileName, fileType) {
    if (!base64String) {
      console.error("base64String is undefined or null");
      return null;
    }

    // Ensure base64 string has the correct prefix
    const base64WithPrefix = base64String.startsWith(`data:${fileType};base64,`)
      ? base64String
      : `data:${fileType};base64,${base64String}`;

    // Split the base64 string and decode it
    const base64Content = base64WithPrefix.split(";base64,").pop();
    try {
      const byteCharacters = atob(base64Content);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; slice.length > i; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new File(byteArrays, fileName, { type: fileType });
    } catch (error) {
      console.error("Error converting Base64 to File:", error);
      return null;
    }
  }

  const isBase64 = (str) => {
    // Check if the string is a base64 string
    const regex = /^data:image\/([a-zA-Z]*);base64,([^\"]*)$/;
    console.log("itsbase64", regex.test(str));
    return regex.test(str);
  };

  async function appendAndSubmitBasicInfo(formData) {
    formData.append("phoneNumSecondary", form.basic.phoneNumSecondary);
    formData.append("firstName", form.basic.firstName);
    formData.append("lastName", form.basic.lastName);

    if (form.theme == -1) {
      formData.append("theme_id", 2);
    } else {
      formData.append("theme_id", form.theme);
    }

    if (form.coverImage !== null && form.coverImage !== "null") {
      if (isBase64(form.coverImage)) {
        formData.append(
          "cover",
          base64ToFile(form.coverImage, "image.png", "image/png")
        );
      }
    } else {
      formData.append("cover", "");
    }
    if (form.profileImage !== null && form.profileImage !== "null") {
      if (isBase64(form.profileImage)) {
        formData.append(
          "photo",
          base64ToFile(form.profileImage, "image.png", "image/png")
        );
      }
    } else {
      formData.append("photo", "");
    }
    if (form.isPersonal == null) {
      formData.append("isPersonal", "true");
    } else {
      formData.append("isPersonal", form.isPersonal);
    }
    formData.append("phoneNum", form.basic.phoneNumber);
    formData.append("email", form.basic.email);
    formData.append("bio", form.basic.bio);
    formData.append("location", form.basic.location);
    if(form.basic.jobTitle ===""  ){
      formData.append("jobTitle", null);
    }
    else{
      formData.append("jobTitle", form.basic.jobTitle);
    }
    if(form.basic.businessName ===""  ){
      formData.append("businessName",null);
    }
    else{
      formData.append("businessName", form.basic.businessName);
    }
   
    if (form.theme == -1) {
      formData.append("theme_id", 2);
    } else {
      formData.append("theme_id", form.theme);
    }

    // Make API call
    if (profile == null || profile == "null") {
      await makeApiCall(formData, "create_personal_data");
    } else {
      await makeApiCall(formData, profile);
    }
  }
  const boxStyle = {
    position: isSmallScreen ? "relative" : "absolute",
    bottom: "20em",
    left: !isSmallScreen && tex?.language !== "ar" ? "20em" : undefined,
    right: !isSmallScreen && tex?.language === "ar" ? "20em" : undefined,
    backgroundColor: "#262626",
    marginTop: isSmallScreen ? "300px" : "0px",
  };
  async function makeApiCall(formData, api) {
   
    setIsLoading(true);

    try {
      const response = await axios.post(
        `https://api.x-tag.tech/api/profile/${api}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log("Response:", response.data);
      console.log("activeStep", activeStep);
      if ( profile !== null && profile !== "null") {
        setActiveStep(activeStep);
        handleSuccessResponse(); // Trigger success modal on successful response

        console.log("activeStep", activeStep);
      }
      //  else
     else   if (activeStep == 2 && (profile == null || profile == "null")) {
        setShowModal(true);
        console.log(response.data?.profile)
        localStorage.setItem('profile',response.data?.profile?.id)
        console.log("activeStep", activeStep);
      } else {
        console.log("activeStep", activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        handleSuccessResponse(); // Trigger success modal on successful response

      }

      //setIsLoading(false);
    } catch (error) {
    handleErrorResponse(error);

      console.error("Error submitting profile data:", error);
      // handle error
     setIsLoading(false);
    }
    finally {
      setIsLoading(false);
    }
  }

  async function appendAndSubmitContactInfo(formData, sortedContacts) {
    // Append primary and secondary contacts
    sortedContacts?.forEach((contact, index) => {
      if (contact.value !== "") {
        formData.append(
          `primaryLinks[${index}][id]`,
          contact.idInthback !== null ? contact.idInthback : index
        );
        formData.append(`primaryLinks[${index}][value]`, contact.value);
      }
    });

    form?.contactsCustom
      .filter((contact) => contact.name || contact.link)
      .filter((contact) => contact.image || contact.logo)
      .forEach((contact, index) => {
        formData.append(`secondLinks[${index}][value]`, contact.name);
        formData.append(`secondLinks[${index}][name_link]`, contact.type);
        formData.append(
          `secondLinks[${index}][link]`,
          contact.name ? contact.name : contact.link
        );

        if (isBase64(contact?.image)) {
          formData.append(
            `secondLinks[${index}][logo]`,
            base64ToFile(contact.image, "image.png", "image/png")
          );
        } else {
          formData.append(`secondLinks[${index}][logo]`, contact.logo);
        }
      });

    form.sections.forEach((section, index) => {
      if (
        (section?.media !== undefined &&
          section?.media !== null &&
          section?.media !== "undefined" &&
          section?.media !== "null" &&
          section?.media !== "" &&
          section?.sectionName !== "") ||
        (section?.content && section?.sectionName !== "")
      ) {
        if (section?.media !== null) {
          formData.append(`sections[${index}][media]`, section.media);
        } else {
          formData.append(
            `sections[${index}][media]`,
            base64ToFile(
              section.content[0].value,
              `section${index}Media`,
              section.contentType === "pdf" ? "application/pdf" : "video/mp4"
            )
          );
        }
        formData.append(`sections[${index}][title]`, section.sectionName);
        formData.append(
          `sections[${index}][name_of_file]`,
          section.sectionName
        );
      }
    });

    if (form.theme == -1) {
      formData.append("theme_id", 2);
    } else {
      formData.append("theme_id", form.theme);
    }

    // Make API call
    if (profile == null || profile == "null") {
      await makeApiCall(formData, "create_links");
    } else {
      await makeApiCall(formData, profile);
    }
  }
  const isArabic = tex?.language === "ar";
  async function appendAndSubmitColorInfo(formData) {
    if (
      form.colors.mainBackground == null ||
      form.colors.mainBackground == "null"
    ) {
      formData.append("bgColor", "#fff");
    } else {
      formData.append("bgColor", form.colors.mainBackground);
    }
    if (
      form.colors.buttonsBackground == null ||
      form.colors.buttonsBackground == "null"
    ) {
      formData.append("buttonColor", "#000");
    } else {
      formData.append("buttonColor", form.colors.buttonsBackground);
    }
    if (form.theme == -1) {
      formData.append("theme_id", 2);
    } else {
      formData.append("theme_id", form.theme);
    }

    // Make API call
    if (profile == null || profile == "null") {
      await makeApiCall(formData, "create_other_data");
    } else {
      await makeApiCall(formData, profile);
    }
  }

  const handleNext = async () => {
    

    console.log("formikRef.current", formikRef.current);
  
    if (activeStep === 0 && formikRef.current) {
      const isValid = await formikRef.current.validateForm();
      const touchedFields = Object.keys(formikRef.current.values).reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {});
  
      formikRef.current.setTouched(touchedFields);
  
      if (isValid && Object.keys(isValid).length === 0) {
        const formData = new FormData();
        appendAndSubmitBasicInfo(formData);
      }
      if (Object.keys(isValid).length > 0) {
        console.log('complete your data')
      }
    } else if (activeStep === 1) {
      const formData = new FormData();
      const sortedContacts = form?.contacts.slice().sort((a, b) => a.id - b.id);
      appendAndSubmitContactInfo(formData, sortedContacts);
    } else if (activeStep === 2) {
      const formData = new FormData();
      appendAndSubmitColorInfo(formData);
    }
  
    // After operations, scroll to the top
   
    if (activeStep === steps.length - 1 && (profile === "null" || profile === null)) {
      setShowModal(true);
    }

    const element = document.getElementById('mainElement');
    if (element) {
      element.scrollTop = 0;
    }
    try {
      const isValid = await formikRef.current.validateForm();
      if (activeStep === 0 && isValid && Object.keys(isValid).length === 0) {
        // handle form submission for step 0
        handleSuccessResponse();
      }
      // else if (activeStep === 0 && isValid && Object.keys(isValid).length >= 0) {
      //   // handle form submission for step 0
      //  console.log('complete your data')
      //  handleErrorResponse(422);
      // }
       else if (activeStep === 1) {
        // handle form submission for step 1
        handleSuccessResponse();
      } else if (activeStep === 2) {
        // handle form submission for step 2
        handleSuccessResponse();
      }
    } catch (error) {
   
    } finally {
     
    }
  };
  

  const handleBack = () => {
    setActiveStep(0);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const goToStep = (stepIndex) => {
    setActiveStep(stepIndex);
  };
  // useEffect(() => {
  //   const handleScroll = throttle(() => {
  //     const stepper = document.getElementById("stepper-container");
  //     if (window.scrollY > 100) {
  //       stepper.style.overflowY = "auto";
  //     } else {
  //       stepper.style.overflowY = "hidden";
  //     }
  //   }, 100); // Throttle period in milliseconds
  
  //   window.addEventListener("scroll", handleScroll);
  
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //     handleScroll.cancel(); // Cancel the throttled event on cleanup
  //   };
  // }, []);
  

  const StepIcon = (props) => {
    const { active, completed, className } = props;

    return (
      <div
        className="w-[2.5rem] h-[2.5rem] flex justify-center items-center rounded-full text-white overflow-x-hidden "
        style={{
          backgroundColor: active || completed ? "#D9A31F" : "#949494",
        }}
      >
        {props.icon}
      </div>
    );
  };
  
    
  
  return (
    <Box
    sx={{ width: "100%", position: "relative", paddingBottom: "3rem", marginTop: '50px',height:"100vh"
   
  }}
      id="stepper-container"
      ref={scrollRef} 
    >
      {errorMessage !== null && errorMessage !== "" && (
        <Box
          sx={{
            backgroundColor: "red",
            color: "white",
            p: 2,
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          {errorMessage}
        </Box>
      )}

      <Modal
        open={showModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ bgcolor: "white", p: 4, borderRadius: 4, textAlign: "center" }}
        >
          <Typography
            variant="h6"
            id="modal-title"
            gutterBottom
            className="text-black"
          >
            {t("successMessage")}
          </Typography>
          <Typography
            variant="h6"
            id="modal-title"
            gutterBottom
            className="text-gray-600"
          >
            {t("infosaved")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalClose}
          >
            {t("returntologin")}
          </Button>
        </Box>
      </Modal>

    

      <Box
        sx={{
          width: { xs: "100%", md: "100%" },
          marginLeft: isArabic ? { md: "auto" } : { md: 0 },
          marginRight: isArabic ? { md: 0 } : { md: "auto" },
          display:"flex",
          justifyContent: (profile === null || profile === "null") ? 'center' : (isArabic ? 'end' : 'start'),
        }}
      >
        <Stepper
          sx={{
            width:{
              xs: "100%",
              sm: "65%",},
            padding: {
              xs: "1rem",
              sm: "2rem",
            },
            ".MuiStepLabel-label": {
              fontSize: {
                xs: "0.8rem",
                sm: "1rem",
              },
              fontFamily: "Helvetica",
            },
            ".MuiStepIcon-root": {
              width: {
                xs: "0.7rem",
                sm: "2rem",
              },
                height: {
                xs: "0.7rem",
                sm: "2rem",
              },
            },
          }}
          activeStep={activeStep}
          nonLinear
          style={{ flexDirection: isArabic ? "row-reverse" : "row" }}
        >
          {profile == null || profile == "null"
            ? steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                return (
                  <Step key={label} {...stepProps}  style={{ marginLeft:'0px' ,
                  marginTop:'-14px' ,
                  textAlign: 'center', // Center align the text
                  flexDirection: 'column-reverse' // Reverse the order to put the icon below the text
              
                  }}>
                <div className='flex justify-center'>
                  <p style={{ padding: "3px" }} className="text-black text-md font-bold">
                  {t(label)}
                </p>
                </div>
                <div className='flex justify-center'>
                    <StepLabel {...labelProps} StepIconComponent={StepIcon}>
                   
                    </StepLabel>
                    </div>
                  </Step>
                );
              })
            : steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step
                    key={label}
                    {...stepProps}
                    onClick={() => goToStep(index)}
                    style={{ marginLeft:'0px' ,
                    marginTop:'-14px' ,
                    
                    }}
                    
                  >
                  <div className='flex justify-center'>

                  <p style={{ padding: "3px" }} className="text-black text-md font-bold">
                  {t(label)}
                </p>
                </div>
                <div className='flex justify-center'>

                    <StepLabel
                      {...labelProps}
                      StepIconComponent={StepIcon}
                      style={{
                        flexDirection: isArabic ? "column-reverse" : "column",
                      }}
                    >
                    </StepLabel>
                    </div>
                  </Step>
                );
              })}
        </Stepper>
      </Box>

      {activeStep === steps.length ? (
        ""
      ) : (
        <React.Fragment >
          {activeStep === 0 ? (
            <Step2 formikRef={formikRef} />
          ) : activeStep === 1 ? (
            <div className="ml-2">
              <Step3 />
            </div>
          ) : activeStep === 2 ? (
            <Step1 />
          ) : (
            ""
          )}
       
          {profile == null || profile == "null" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: tex === "ar" ? "row-reverse" : "row",
                p: 2,
                justifyContent: "flex-start",  // Adjust this to control spacing
                gap: "10px",  // Adds a small gap between the buttons
                marginTop:"40px",
                marginBottom:"20px"
              }}
            >
              {activeStep > 0 && (
                <Button
                  onClick={handleBack}
                  sx={{
                    border: "1px solid gray",
                    padding: "2px",
                    borderRadius: "4px",
                    backgroundColor: "#262626",
                    color: "#fff",
                  }}
                >
                  {t("Back")}
                </Button>
              )}
              {activeStep === steps.length - 1 ? (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isLoading}
                  sx={{
                    width: "200px",
                    backgroundColor: "#262626",
                    color: "#fff",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "gray",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  ) : (
                    t("Finish")
                  )}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleNext}
                  disabled={isLoading}
                  sx={{
                    width: "200px",
                    backgroundColor: "#262626",
                    color: "#fff",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  ) : (
                    t("Next")
                  )}
                </Button>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: tex === "ar" ? "row-reverse" : "row",
                p: 2,
                justifyContent: tex === "ar" ? "end" : "center",
                marginLeft: { xs: "0" },
                 marginTop:"40px",
                marginBottom:"20px"
              }}
            >
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={isLoading}
                sx={{
                  width: "200px",
                  backgroundColor: "#262626",
                  color: "#fff",
                  position: "relative",
                  left: 0,
                  right: 0,
                  marginTop: "0",
                }}
              >
                {isLoading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "gray",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                ) : (
                  t("save")
                )}
              </Button>
            </Box>
          )}
          
        </React.Fragment>
      )}
      {errorMessage === "An unexpected error occurred. Please try again." ||
        (errorMessage === "Server error. Please try again later." && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              Reload Page
            </Button>
          </Box>
        ))}
        <Modal open={modalOpen} onClose={handleModalCloseHessage}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='text-white'>
            {modalMessage}
          </Typography>
         
        </Box>
      </Modal>

     {isLoading ===true &&
      <div className="fixed top-0 left-0 bg-white z-50 w-screen h-screen flex justify-center items-center">
        <CircularProgress />
      </div>}
    </Box>
  );
};

export default StepperComponent;

