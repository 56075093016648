import { useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import { AiOutlineRollback } from "react-icons/ai";
import { BiHomeSmile, BiLogOut } from "react-icons/bi";
import { HiOutlineChatBubbleBottomCenterText } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiMenu2Line } from "react-icons/ri";
import { IoLanguageOutline } from "react-icons/io5";
import { useLanguage } from '../hook/useLocalStorage';
import Logo from '../assets/images/logovertical.png'; // Make sure to use the correct path to your logo
import { CgScreen } from "react-icons/cg";
import { GrLanguage } from "react-icons/gr";
import poweredByXtag from '../assets/images/poweredByXtag.png'
export const Sidebar2 = () => {
  const [open, setOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => setOpen(false));
  const toggleSidebar = () => {
    // Toggle the open state
    setOpen((prev) => !prev);
  
    // Allow for state updates and DOM rendering
    setTimeout(() => {
      const stepperContainer = document.getElementById('stepper-container');
      if (stepperContainer) {
        // Find the nearest scrollable parent if stepper-container itself is not scrollable
        let scrollableParent = stepperContainer.parentNode;
        while (scrollableParent) {
          if (scrollableParent.scrollHeight > scrollableParent.clientHeight) {
            break; // Found the scrollable parent
          }
          scrollableParent = scrollableParent.parentNode;
        }
  
        if (scrollableParent) {
          scrollableParent.scrollTop = 0; // Scrolls the parent to the top
        } else {
          // Fallback to scrolling the stepper container itself if no scrollable parent found
          stepperContainer.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }
    }, 0); // Short delay to ensure the DOM is updated
  };
  
    
  const toggleLanguageDropdown = () => setLanguageOpen((prev) => !prev);
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const tex = useLanguage()

  const { language, setLanguage } = useLanguage();
  const [selectedLanguage, setSelectedLanguage] = useState(tex?.language || "en");
  const isArabic = localStorage.getItem("lann") === "ar";

  const getItemClass = (href) => `
  flex items-center gap-3 p-4 text-sm font-medium transition-all border-b border-gray-700 hover:bg-gray-800 ${
    tex?.language === "ar" ? "flex-row-reverse" : ""
  } ${
    location.pathname === href && !isArabic ? "active-link" : location.pathname === href && isArabic? 'active-link-arabic':"text-[#e5cd79]"
  }`;


  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    setLanguage(language)
    localStorage.setItem("lann", language);
    toggleLanguageDropdown();
  };

  return (
    <div className="overflow-x-hidden" style={{ zIndex: "1000" }}>
      <div className="bg-[#000] h-20 flex items-center px-4 z-50">
        <button
          onClick={toggleSidebar}
          aria-label="toggle sidebar"
          style={{
            color: "white",
            padding: "0.5rem",
            borderRadius: "5px",
            fontWeight: "bold",
            outline: "none",
            cursor: "pointer",
            fontSize: "1.2rem",
            alignItems: "center",
          }}
        >
          <RiMenu2Line color="#D9A31F" size={30} />
        </button>
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {open && (
          <>
            <motion.div
              {...framerSidebarBackground}
              aria-hidden="true"
              className="fixed bottom-0 left-0 w-full top-0 z-40 bg-black opacity-50 overflow-y-hidden"
            ></motion.div>
            <motion.div
              {...framerSidebarPanel}
              className={`fixed top-0 bottom-0 ${isArabic ? 'right-0':'left-0'} z-50 w-64 h-screen border-r border-gray-700 bg-[#1a1a1a]`}
              ref={ref}
              aria-label="Sidebar"
            >
              <div className="flex items-center justify-center text-[#e5cd79] p-5 border-b  border-gray-700">
                <img src={Logo} alt="Logo" className="w-32 h-auto" />
            {/**    <button
                  onClick={toggleSidebar}
                  className="p-2 border border-gray-700 rounded-full"
                  aria-label="close sidebar"
                >
                  <AiOutlineRollback />
                </button>
              */} 
              </div>
              <ul className="flex-grow">
                {items.map((item, idx) => {
                  const { title, href, Icon } = item;
                  return (
                    <li key={title}>
                      <div
                        onClick={() => {
                          toggleSidebar();
                          navigate(href);
                        }}
                        className={getItemClass(href)}
                      >
                      <motion.div {...framerIcon}>
                          <Icon className="text-lg text-white" />
                        </motion.div>
                        <motion.span {...framerText(idx)} className='text-white'>
                          {t(`${title}`)}
                        </motion.span>
                        
                      </div>
                    </li>
                  );
                })}
                <li>
                  <div
                    onClick={toggleLanguageDropdown}
                    className="flex items-center justify-start gap-3 p-4 text-sm font-medium transition-all border-b border-gray-700 hover:bg-gray-800 text-[#fff] cursor-pointer"
                  > <motion.div>
                  <GrLanguage className="text-lg text-white" />
                </motion.div>
                    <motion.span>{t("Language")}</motion.span>
                   
                  </div>
                  {languageOpen && (
                    <div className="pl-10 bg-gray-800 text-[#e5cd79]">
                      <div className="py-2 cursor-pointer" onClick={() => changeLanguage("en")}>English</div>
                      <div className="py-2 cursor-pointer" onClick={() => changeLanguage("ar")}>العربية</div>
                    </div>
                  )}
                </li>


                <div
                onClick={() => navigate("/")}
                className=" w-full flex items-center justify-center p-4 text-[#fff] bg-[#403F3F] hover:bg-gray-800 border-y border-gray-700 cursor-pointer"
              >
                <BiLogOut className="text-lg mr-2 text-white" />
                <span>{t("Logout")}</span>
              </div>

              <div
              onClick={() => window.open('http://www.x-tag.tech/')}
              className="0 w-full flex items-center justify-center p-4  cursor-pointer"
            >
             
             <img src={poweredByXtag}/>
            </div>
              </ul>
            
              
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

const items = [
  { title: "Home", Icon: BiHomeSmile, href: "/Steper" },
  { title: "dashboard", Icon: CgScreen, href: "/dashboard" },
  // { title: "setting", Icon: BiHomeSmile, href: "/setting" }, // Assuming you have a setting route
];

const framerSidebarBackground = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0.2 } },
  transition: { duration: 0.3 },
};

const framerSidebarPanel = {
  initial: { x: "-100%" },
  animate: { x: 0 },
  exit: { x: "-100%" },
  transition: { duration: 0.3 },
};

const framerText = (delay) => {
  return {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    transition: {
      delay: 0.5 + delay / 10,
    },
  };
};

const framerIcon = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: {
    type: "spring",
    stiffness: 260,
    damping: 20,
    delay: 1.5,
  },
};
