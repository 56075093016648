import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import Template2 from "../Pages/Templates/Template2/Template2";
import Template6 from "../Pages/Templates/Template6/Template6";
import Template8 from "../Pages/Templates/Template8/Template8";
import Template12 from "../Pages/Templates/Template12/Template12";
import Template14 from "../Pages/Templates/Template14/Template14";
import Template15 from "../Pages/Templates/Template15/Template15";
import Template16 from "../Pages/Templates/Template16/Template16";
import Temp12 from "../Pages/Templates/tem18/Temp18";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useSelector, useDispatch } from "react-redux";
import 'swiper/css';
import 'swiper/css/navigation';
import { setTheme } from "../Redux/Slices/FormSlice";
import { FaPlus } from "react-icons/fa";
import SpeedDial from "@mui/material/SpeedDial";
import { PiUploadSimpleBold } from "react-icons/pi";
import tinycolor from "tinycolor2"; 
import { useTranslation } from "react-i18next";

const TemplatePreview = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const form = useSelector((state) => state.form || {});
  const { theme = -1, colors = {} } = form;
  const [textcolor, setTextColor] = useState("#ffffff");
  const [textbottoncolor, setTextBottonColor] = useState("#ffffff");
  const [oppen, setOppen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(form.theme);
  const swiperRef = useRef(null); // Reference for Swiper
  useEffect(() => {
    if(form.theme !==-1){

   
    setSelectedTemplate(form.theme - 1); }
    else{
      setSelectedTemplate(1); 
    }
  }, [form.theme]);
  const [counterForAutoScrool ,setcounterForAutoScrool]=useState(0)
  useEffect(() => {
    if(form.theme !==-1 && counterForAutoScrool==0 && selectedTemplate !==1 && selectedTemplate!==2){
      setcounterForAutoScrool(counterForAutoScrool+1)

    
    // Navigate to the selected slide on component mount
    if (swiperRef.current && selectedTemplate !== null) {
      swiperRef.current.swiper.slideTo(selectedTemplate, 0); // Navigate without animation
    }
  } 
  }, [selectedTemplate]);
  useEffect(() => {
    const mainBackgroundColor = form?.colors?.mainBackground;

    if (mainBackgroundColor) {
      const brightness = tinycolor(mainBackgroundColor).getBrightness();
      setTextColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form?.colors?.mainBackground]);

  useEffect(() => {
    const buttonsBackground = form?.colors?.buttonsBackground;

    if (buttonsBackground) {
      const brightness = tinycolor(buttonsBackground).getBrightness();
      setTextBottonColor(brightness > 128 ? "#000000" : "#ffffff");
    }
  }, [form?.colors?.buttonsBackground]);

  useEffect(() => {
    if (localStorage.getItem('theme') === '-1') {
      localStorage.setItem('currentIndex', theme + 1);
    }
  }, [theme]);

  const handleThumbnailClick = (index) => {
    setSelectedTemplate(index);
    dispatch(setTheme({ theme: index + 1 }));
    localStorage.setItem('currentIndex', index + 1);
    localStorage.setItem('theme', index + 1);
  };

  const renderTemplate = () => {
    switch (selectedTemplate) {
      case 1:
        return <Template2 />;
      case 2:
        return <Template6 />;
      // case 3: 
      //   return <Template7 />;
      case 4:
        return <Template8 />;
      // case 5:
      //   return <Template9 />;
      // case 6:
      //   return <Template11 />;
      case 7:
        return <Template12 />;
      case 8:
        return <Template15 />;
      case 9:
        return <Template14 />;
      // case 10:
      //   return <Template10 />;
      case 11:
        return <Template16 />;
      // case 12:
      //   return <Template17 />;
      case 13:
        return <Temp12 />;
      default:
        return <Template2 />;
    }
  };

  const iPhoneFrame = {
    width: "100%",
    maxWidth: "300px",
    height: "500px",
    border: "none",
    borderRadius: "40px",
    overflow: "hidden",
    position: "relative",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  };

  const iPhoneScreen = {
    width: "100%",
    height: "500px",
    overflowX:"hidden",
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "thin",
    scrollbarColor: "transparent transparent",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  };

  const iPhoneSpeaker = {
    height: "40px",
    backgroundColor: "black",
    position: "absolute",
    top: "-20px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "120px",
    borderRadius: "10px",
    zIndex: "200"
  };

  const iPhoneCamera = {
    height: "10px",
    backgroundColor: "blue",
    position: "absolute",
    top: "4px",
    left: "64%",
    width: "10px",
    borderRadius: "50%",
    zIndex: "200"
  };

  const iPhoneButton = {
    height: "0px",
    backgroundColor: "#000",
    position: "absolute",
    bottom: "4px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100px",
    borderRadius: "2px",
    zIndex: "400"
  };

  const iPhoneFrameStyle = {
    ...iPhoneFrame,
    border: `10px solid black`,
  };

  const iPhoneButtonStyle = {
    ...iPhoneButton,
    backgroundColor: "black",
  };

  const StylediPhoneFrame = ({ children }) => (
    <Box sx={iPhoneFrameStyle} >
      <div style={iPhoneSpeaker} />
      <div style={iPhoneCamera} />
      {children}
      <div style={iPhoneButtonStyle} /> 
    </Box>
  );

  return (
    <div className="relative flex flex-col items-center overflow-x-hidden overflow-y-hidden mt-10  hide-scrollbar ">
    <h2 style={{ fontFamily:'Helvetica'}} className="  text-lg mt-2 mb-1"> {t("Select_a_template")}</h2>
   
 
    <Swiper
    ref={swiperRef} // Attach the ref to Swiper component
    slidesPerView={4.5} // Not directly supported to show half, so will need CSS adjustments
    spaceBetween={3}
    initialSlide={selectedTemplate} // Dynamically set the initial slide based on the selected template

    navigation={{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }}
    modules={[Navigation]}
    className="thumbnailSwiper mt-2 max-w-[100%] overflow-x-auto relative"
    style={{ maxWidth: "350px", maxHeight: '100px' }}
  >
  <div className="swiper-button-prev" style={{ position: 'absolute', left: '-25px', top: '50%', transform: 'translateY(-50%)' }}></div>
  <div className="swiper-button-next" style={{ position: 'absolute', right: '-25px', top: '50%', transform: 'translateY(-50%)', zIndex: 50 }}></div>
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md  flex items-center justify-center  ${
          selectedTemplate === 1 ? "bg-[#242424] text-white text-[23px]" : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(1)}
      >
        1
      </Box>
    </SwiperSlide>
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 2 ?"bg-[#242424] text-white text-[23px] " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(2)}
      >
        2
      </Box>
    </SwiperSlide>
   {/**  <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 3 ?"bg-[#242424] text-white text-[23px]" : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(3)}
      >
        3
      </Box>
    </SwiperSlide>
    */}
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 4 ?"bg-[#242424] text-white text-[23px]" : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(4)}
      >
        3
      </Box>
    </SwiperSlide>
  {/**  <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center ${
          selectedTemplate === 5 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(5)}
      >
        5
      </Box>
    </SwiperSlide>
    
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 6 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(6)}
      >
        4
      </Box>
    </SwiperSlide>
    */} 
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 7 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(7)}
      >
      4
      </Box>
    </SwiperSlide>
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 8 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(8)}
      >
        5
      </Box>
    </SwiperSlide>
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 9 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(9)}
      >
      6
      </Box>
    </SwiperSlide>
  {/**  <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center ${
          selectedTemplate === 10 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(10)}
      >
        10
      </Box>
    </SwiperSlide>
    */} 
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 11 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(11)}
      >
        7
      </Box>
    </SwiperSlide>

    <SwiperSlide>
    <Box
      className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
        selectedTemplate === 13 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
      }`}
      onClick={() => handleThumbnailClick(13)}
    >
      8
    </Box>
  </SwiperSlide>
   {/** <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 12 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(12)}
      >
        12
      </Box>
    </SwiperSlide>
    
    <SwiperSlide>
      <Box
        className={`w-10 h-10 cursor-pointer rounded-md flex items-center justify-center  ${
          selectedTemplate === 13 ?"bg-[#242424] text-white " : "bg-[#fff] text-black border-2 border-black"
        }`}
        onClick={() => handleThumbnailClick(13)}
      >
        13
      </Box>
    </SwiperSlide>
    */} 
    <div className="swiper-button-next" style={{ position: 'absolute', right: '-25px', top: '50%', transform: 'translateY(-50%)', zIndex: 50 }}></div>
  </Swiper>
      <StylediPhoneFrame className=''>
        <Box sx={iPhoneScreen}>
          { (
            <>
            {selectedTemplate !== 7 && selectedTemplate !== 9 &&
              <div 


              style={{ backgroundColor: form?.colors?.buttonsBackground,zIndex:"900"
             
             ,color: textbottoncolor,border:"2px solid",borderColor:textbottoncolor}}
             className='flex items-center  absolute right-2 rounded-full mb-2 bottom-[13px] text-center text-xs px-1 z-50'>
             <div 
             style={{ backgroundColor: form?.colors?.buttonsBackground,borderColor:textbottoncolor}}
                 className='w-fit py-3 px-3 rounded-full -ml-2 border-2  p-1'><FaPlus color={textbottoncolor} size={19} className=''/></div>
                   <p
                   
                   className=" whitespace-nowrap pl-[3px] text-[14px] font-bold " style={{color: textbottoncolor,fontWeight:'bold'}}>Add To Contacts</p>   
                   </div>
             
             
                }

              <button 
              style={{ zIndex:"900"}}
              className="absolute left-3 bottom-2 w-14 h-14 rounded-full flex justify-center items-center text-[var(--button-text-color)] z-50">
                <Box
                  sx={{ height: 1, transform: "translateZ(0px)", flexGrow: 1 }}
                  style={{
                    bottom: "10px",
                    left: "2px",
                    whiteSpace: "nowrap",
                    zIndex: "0",
                    color: `white`,
                  }}
                >
                  <SpeedDial
                    onClick={() => setOppen(!oppen)}
                    ariaLabel="SpeedDial basic example"
                    sx={{
                      position: "absolute",
                      bottom: 10,
                      left: 16,
                      zIndex: 40,
                      "& .MuiSpeedDial-fab": {
                        backgroundColor: `${form?.colors?.buttonsBackground} !important`,
                        
                      },
                    }}
                    icon={
                      <div
                        style={{
                          borderRadius: "50%",
                          padding: "12px",
                          border: "2px solid", borderColor: textbottoncolor
                        }}
                      >
                        <PiUploadSimpleBold size={28} color={textbottoncolor} />
                      </div>
                    }
                  >
                  </SpeedDial>
                </Box>
              </button>
            </>
          )}

          <div className="swiper-slide swiper-slide-width" style={{ width: '300px' }}>
            {renderTemplate()}
          </div>
        </Box>
      </StylediPhoneFrame>

     
    </div>
  );
};

export default TemplatePreview;
