import "./App.css";
import ThemeRoutes from "./Routes/Routes";
import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import Logo from "./assets/images/Logo.png";
import { Sidebar2 } from "./Components/Sidebar2";
import { useNavigate } from "react-router-dom";
import { LanguageProvider, useLanguage } from "./hook/useLocalStorage";
import { useEffect, useState } from "react";
import LanguageSwitcher from "./Components/LanguageSwitcherNaveBare";

function App() {
  let navigate = useNavigate();
  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#000",
      },
      secondary: {
        main: "#F8F4A2",
      },
      lighter: {
        main: "#FCFAD3",
      },
    },
    typography: {
      title: {
        color: "#4e4d49",
        fontSize: "0.9rem",
        display: "block",
      },
      linkSelected: {
        color: "#e5cd79",
        backgroundColor: "#fff",
        fontSize: "0.9rem",
        display: "block",
      },
    },
  });

  const location = useLocation();
  const { language } = useLanguage();
  const [languagee, setLanguagee] = useState("en");

  useEffect(() => {
    console.log("Language changed:", language);
  }, [language]);

  return (
    <div
      className="App overflow-x-hidden max-h-[100vh] overflow-y-auto "
      id="mainElement"
      style={{ fontFamily: "Helvetica" }}
    >
      <div className="bg-[#fff] fixed w-screen  -z-10 top-0 left-0 right-0 bottom-0"></div>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <div>
            {location.pathname !== "/Forgetpassword" && (
              <Stack
                p={"1rem"}
                direction="row"
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  backgroundImage: "linear-gradient(to top, #fff, #fff)",
                  position: "relative",
                }}
              >
                {location.pathname !== "/" && (
                  <>
                    <Box
                      sx={{
                        position: "absolute",
                        [language == "ar" ? "right" : "left"]: 0,
                      }}
                      className="z-50"
                    >
                      <Sidebar2 />
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        [language == "ar" ? "left" : "right"]: {
                          xs: 5,
                          md: '40vw',
                        },
                      }}
                      className="z-20"
                    >
                      <LanguageSwitcher language={languagee} />
                    </Box>
                  </>
                )}
                <Box
                 
                  className="bg-[#000] flex justify-center z-10 mt-3"
                  sx={{
                    position: "relative",
                    left:
                      location.pathname === "/Steper" && language == "en"
                        ? { xs: 0, md: "-14em" }
                        : location.pathname === "/Steper" && language !== "en"
                        ? { xs: 0, md: "14em" }
                        : 0,
                  }}
                >
                  <img width={"100px"} src={Logo} alt="Logo" />
                </Box>

                <div className="bg-[#000] z-0 absolute top-0 left-0 right-0 h-[10em]"></div>
              </Stack>
            )}

            <div>
              <ThemeRoutes />
            </div>
          </div>
        </I18nextProvider>
      </ThemeProvider>
    </div>
  );
}

export default () => (
  <LanguageProvider>
    <App />
  </LanguageProvider>
);
