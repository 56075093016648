import React, { useEffect, useState } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  setColors,
  setTheme,
  setProfileImage,
  setBasicInfo,
  setContacts,
  setCustomContacts,
  setCoverImage,
  setSections,
  resetState,
} from "../../Redux/Slices/FormSlice";
import { useDispatch, useSelector } from "react-redux";
import loginImage from "../../assets/images/image10.png";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const form = useSelector((state) => state.form);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  

  // Function to reset the form state
  const resetFormState = () => {
    dispatch(resetState());
  };

  useEffect(() => {
    // Call the reset function when the comp onent mounts
    resetFormState();
    // Clear local storage or any other initializations
    localStorage.clear();
  }, [dispatch]); // Add dispatch to the dependency array

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    localStorage.clear();
  }, []);
  const handleLogin = () => {
    setIsLoading(true);
    axios
      .post("https://api.x-tag.tech/api/login", {
        username: email,
        password: password,
      })
      .then((response) => {
        const token = response.data.token;
        const profile = response.data.user.profile;
        localStorage.setItem("token", token);
        localStorage.setItem("profile", profile);

        console.log(response.data.user.profile);
        if (profile !== null) {
          return axios.get(
            `https://api.x-tag.tech/api/profile/${profile}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          // If profile is null, skip further processing and navigate to the next page
          dispatch(resetState());
          navigate("/Steper");
          return null; // Return null to stop the promise chain
        }
      })

      .then((userResponse) => {
        const formData = userResponse.data.data;

        console.log("formData", formData);

        dispatch(
          setColors({
            buttonsBackground: formData?.buttonColor,
            mainBackground: formData?.bgColor,
          })
        );
        dispatch(setTheme(formData?.theme));

        dispatch(setProfileImage({ image: formData?.photo }));
        dispatch(setCoverImage({ coverImage: formData?.cover }));
        dispatch(
          setBasicInfo({
            basic: {
              firstName: formData?.firstName,
              lastName: formData?.lastName,
              jobTitle: formData?.jobTitle,
              businessName: formData?.businessName,
              location: formData?.location,
              bio: formData?.bio,
              phoneNumber: formData?.phoneNum,
              email: formData?.email,
              phoneNumSecondary: formData?.phoneNumSecondary,
            },
          })
        );

        formData?.primary_links?.forEach((contact) => {
          dispatch(
            setContacts({
              type: contact.name,
              value: contact.value,
              idInthback: contact.id, // Make sure this is correctly passed
              logo: contact.logo,
              // idInthback:contact.id
            })
          );
        });

        formData?.second_links?.forEach((customContact) => {
          dispatch(
            setCustomContacts({
              id: customContact.id,
              link: customContact.link,
              logo: customContact.logo,
              name_link: customContact.name_link,
            })
          );
        });

        const sectionsToAdd = formData?.section?.map((section) => ({
          id: section.id,
          sectionName: section.title,
          media: section.media,
          contentType: "video",
        }));

        if (sectionsToAdd) {
          dispatch(setSections(sectionsToAdd));
        }

        setIsLoading(false);
        console.log(form);
        navigate("/Steper");
      })
      .catch((err) => {
        console.error("Login failed:", err);
        setError("Login failed. Please check your credentials.");
        setIsLoading(false);
      });
  };

  return (

    <div>
<div className="fixed -z-10 top-0 bottom-0 right-0 left-0 bg-[#262626] "></div>
      <div className="flex sm:flex-row flex-col justify-between  items-center m-auto h-full w-full bg-[#262626] ">
  <div class="flex flex-col items-center justify-center mx-auto sm:h-screen  mt-[20%] md:mt-0 ">
  
      <div class="sm:w-[110%]  md:w-[140%] bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 z-10">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-[#D9A31F] md:text-2xl py-4" style={{fontSize:"2.4rem"}}>
                  Login
              </h1>
              <form class="space-y-4 md:space-y-6" action="#">
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email/User Name*</label>
                      <input  value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" class="bg-gray-50 border text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 border-gray-600 " placeholder="name@company.com/name" required=""/>
                  </div>
                   <div>
      <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
        Password
      </label>
      <div className="relative">
        <input
          type={showPassword ? 'text' : 'password'}
          name="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="••••••••"
          className="border-gray-600 bg-gray-50 border text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
          required
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600 dark:text-gray-300 focus:outline-none"
        >
          {showPassword ? (
            <VisibilityOff />
          ) : (
            <Visibility />
          )}
        </button>
      </div>
    </div>
    {error && <Typography color="error">{error}</Typography>}
                  <Button
                    onClick={handleLogin}
                    fullWidth
                    variant="contained"
                    sx={{
                      "&:hover": { backgroundColor: theme.palette.primary.dark },
                      background:
                        isLoading || email == "" || password == ""
                        ? "gray"
                        : "linear-gradient(to right, rgba(229, 205, 121, 0.4), rgba(229, 205, 121, 0.7))",
              // ... your other styles
            }}
            style={{
              backdropFilter: "blur(8px)", // Glass effect
              border: "1px solid rgba(229, 205, 121, 0.5)", // Golden border
              boxShadow:"0 4px 8px rgba(0, 0, 0, 0.3), 0 6px 20px rgba(0, 0, 0, 0.3)", // Shadow for 3D effect
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              padding: "0.3rem 0.5rem",
              transition: "transform 0.3s ease",
              display: "flex",
              justifyContent: "center",
              height: "2.7rem",
              zIndex: "20",
              marginTop: "2rem",
              marginLeft: "0rem",
              position: "relative",
              background:"#262626"
            }}
            disabled={isLoading || email === "" || password === ""}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                style={{
                  color: "black",
                  position: "absolute", // To overlay the loader on the button
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px", // Half of the size to center it vertically
                  marginLeft: "-12px", // Half of the size to center it horizontally
                }}
              />
            ) : (
              <h4 style={{ color: "white" }}> Login </h4>
            )}
          </Button>
           <div class="flex flex-col items-center justify-between gap-1">
                      <a href="#" class="text-sm font-medium text-primary-600 hover:underline text-[#D9A31F] mx-auto"  >Forgot password?</a>
                      <p class="text-sm font-light text-gray-500 text-center ">
                     By proceeding, you agree terms & conditions.
                  </p>
                  </div>
                  
              </form>
          </div>
      </div>
  </div>
          <div className="md:w-[50%] sm:w-[50%] ">
          <img
          src={loginImage}
          alt="Login"
          style={{ maxWidth: "500px" }}
          className="md:w-[100%] sm:w-[100%] w-[100%] opacity-0 md:opacity-100"
          />
          </div>
      </div>
    </div>
  );
};

export default Login;
